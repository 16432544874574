import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';
import { genres } from '../../utils/constants'

import './home.scss'

export const Home = () => {
    const history = useHistory();

    useEffect(() => {
        ReactGA.pageview('Inicio');
    }, [])


    const choose_genre = (idGener) => {
        history.push(`/genre/${idGener}`);
    }


    return (
        <div className="screen-home">

            <div className='body-home'>

                <div className='col-12 col-md-6 d-flex flex-column align-items-end'>
                    <div className='picture-title'>
                        <h2 className='title-h2'>
                            Biblioteca
                        </h2>
                        <h2 className='title-h2'>
                            digital 
                        </h2>
                        <h2 className='title-h2'>
                            ecuatoriana
                        </h2>
                    </div>
                    <div className='hexa-home'></div>
                </div>
                <div className='col-12 col-md-6 pe-md-5'>
                    <div className='home-description'>
                        <p className="subtitle">Esta «Biblioteca digital ecuatoriana» es un proyecto de difusión
                            literaria que nació en el contexto de la pandemia. Aprovechando
                            la omnipresencia de los ecosistemas virtuales, busca hacer llegar
                             —de modo online y gratuito— al público textos contemporáneos
                            escritos en Ecuador que ya han circulado en papel.<br/><br/>
                            En ese afán multiplicador, esta plataforma ha sido pensada para
                            ámbitos de mediación de lectura: aulas de colegio y universidad,
                            talleres literarios, clubes de libro, y lectores y lectoras que se
                            encuentran fuera del país (y con poco acceso a las publicaciones
                            que se dan dentro de nuestras fronteras). Así, además de los
                            textos literarios, aquí encontrarán materiales como entrevistas con
                            los autores, estudios de introducción a sus obras y sugerencias de
                            actividades para los procesos de mediación de lectura.<br/><br/>
                            Como toda biblioteca, este repositorio digital se encuentra en
                            permanente proceso de crecimiento, y busca por varios medios la
                            posibilidad de continuar editando y publicando más de la rica
                            variedad de obras que se están escribiendo en el país. <br /><br />
                            <span className="author">La Caracola Editores</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className='menu-genre'>
                <p className='genre-access'>Accede a textos de:</p>
                <div className='container-boxes'>
                    <div className='box-left'></div>
                    <div className='box-right'></div>
                </div>
                <div className='choose-genre'>
                    {
                        genres && genres.map((currentGenre, index) => {
                            return (
                                <div key={'current' + index} className='genre' onClick={() => choose_genre(currentGenre.idGenre)}>
                                    <div className='btn-text'>{currentGenre.name}</div>
                                    <div className='line-genre'></div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
