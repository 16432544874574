import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';

import { appRoutes, genres } from './../../utils/constants'
import { authors } from './../../utils/data'


import logo from './../../assets/logo-caracola.png'
import './header.scss'


export const Header = () => {

    const location = useLocation();
    const { pathname } = location;
    const [currentPathname, setCurrentPathname] = useState();


    useEffect(() => {
        const logoPathname = pathname === '/';
        setCurrentPathname(logoPathname);
    }, [pathname])

    return (
        <>
            <div className="header">
                <div className="logo-box">

                    <Link
                        className="navbar-brand"
                        to={appRoutes.home}
                    >
                        {
                            currentPathname
                                ? <img alt="Caracola" src={logo} className="logo" />
                                : <h1 className='title-h1'>Biblioteca <br />digital ecuatoriana</h1>
                        }


                    </Link>
                </div>
            </div>
            <div className="header1">
                <div className="nav-links-bar">
                    <NavLink
                        className="nav-link"
                        exact
                        to={appRoutes.home}
                    >
                        Inicio
                    </NavLink>
                    <div className="position-relative author-link-btn">
                        <div className='line-separator'></div>
                    </div>
                    <div className="position-relative author-link-btn">
                        <button className="nav-link">
                            Géneros
                        </button>
                        <div className="list-menu author-list position-absolute">
                            <ul>
                                {
                                    genres && genres.map((currentGenre, index) => {
                                        return (
                                            <li key={"genre-link-" + index}>
                                                <NavLink
                                                    to={'/genre/' + currentGenre.idGenre}
                                                >
                                                    {currentGenre.name}
                                                </NavLink>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="position-relative author-link-btn">
                        <div className='line-separator'></div>
                    </div>
                    <div className="position-relative author-link-btn">
                        <button className="nav-link">
                            Autores
                        </button>
                        <div className="list-menu author-list position-absolute">
                            <ul>
                                {
                                    authors && JSON.parse(JSON.stringify(authors)).sort((p, p2) => { return p.orderName - p2.orderName; }).map((currentAuthor, index) => {
                                        return (
                                            <li key={"author-link-" + index}>
                                                <NavLink
                                                    to={'/author/' + currentAuthor.id}
                                                >
                                                    {currentAuthor.name}
                                                </NavLink>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    {/*<div className="position-relative author-link-btn">
                        <div className='line-separator'></div>
                    </div>
                    <NavLink
                        activeClassName="active"
                        className="nav-link"
                        exact
                        to={appRoutes.sponsors}
                    >
                        Auspiciantes
                    </NavLink>*/}

                </div>
            </div>
        </>
    )
}
