export const criticalStudy5 = `
<h2>Se oye un grito en una isla lejana</h2>
<p class="author-name">Por Abril Altamirano</p>
<p>Escribir es nombrar con la mano lo impronunciable. La buena poesía —la que penetra y desgarra— es aquella que parte de un silencio prolongado, que se ha vuelto incontenible y estalla, de repente, en un grito. «El poema es una boca que habla por sí sola», dice entre versos Yuliana Ortiz Ruano (Esmeraldas, 1992). Yo agregaría, también, que es una boca que muerde. Una pulsión que «obliga y arrastra como un vicio penoso —diría Clarise Lispector—. Una maldición que salva»<sup>1</sup>. La obra de Ortiz Ruano, encapsulada en este <i>Archivo de la desobediencia</i>, se inicia con una herida, con el primer llanto de la voz poética que nace: </p>
<p class="p-all-init-space">Escribo para no morir, <br/>
mas muero a diario <br/>
mi vida<br/>
tiene tantos funerales como cicatrices en la piel</p>
<p>Tomo la cita del poema «Soy la que guarda pieles», incluido en <i>Sovoz</i> (Hanan Harawi, Perú, 2016), su primer poemario publicado, en el que una joven Yuliana Ortiz Ruano vierte la consciencia de un cuerpo que siente, reconoce el dolor y se atreve a nombrarlo. Hay, en sus primeros versos, una furia mezclada con desconcierto; un temblor y un silencio que es roto solo por poemas breves, incisivos, dolorosos como los segundos que siguen al encuentro con lo nefando. Habla una voz que despierta a una realidad donde el hogar ya no es un lugar seguro. Dice el poema IV de <i>Sovoz</i>, que abre la selección de <i>Archivo de la desobediencia</i>: </p>
<p class="p-all-init-space">A mi vuelta<br/>
madre ha escondido<br/>
los cadáveres en el entretecho, <br/>
finjo no saber de ellos</p>
<p>Algo se pudre dentro del hogar; sus habitantes se descomponen ante los ojos desesperados de quien abandona el paraíso de la niñez. La muerte se toma la casa y quien la habita <i>finge</i> no darse cuenta. Calla. La voz se torna silencio y el silencio es una isla. A la poesía de la autora esmeraldeña —nacida en Limones, una pequeña isla al norte de Ecuador, cerca de la frontera con Colombia— la atraviesa una búsqueda que parte de la imposibilidad del habla. El lenguaje como isla distante, incomunicada; un escenario que remite a la infancia en el paisaje insular, a la dicotomía entre el límite-frontera y el horizonte azul infinito; a la vida en un territorio no señalado en el mapa, inexistente para el resto del mundo: </p>
<p class="p-all-init-space">¿Ves eso que traen las olas? <br/>
Eso es el retrato de lo que fuimos <br/>
porque somos muerte <br/>
acumulación de huesos que gritan <br/>
lengua que no podemos codificar<sup>2</sup>. <br/>
<p>Desde ese lugar donde la voz se hace eco y se pierde sin llegar a un destinatario, Ortiz Ruano se atreve y grita. En el poema que grita, que desangra, la autora da sus primeros pasos hacia la revelación de que «escribir es buscar entender, es buscar reproducir lo irreproducible»<sup>3</sup>. En su segundo libro, <i>Canciones desde el fin del mundo</i> (Amauta&Yaguar, Argentina, 2018; Kikuyo Editorial, Ecuador, 2020; Liberoeditorial, España, 2021), el poema se convierte en un intento por comprender y aprehender la realidad, en todo el amplio espectro entre lo bello y lo terrible. Ortiz Ruano escribe <i>desde</i> el fin del mundo, desde el último bastión de un país indetectable donde reina la escasez, el mar se traga pueblos enteros y los militares se pasean armados por las calles. Escribe desde el vértigo y la desazón de quien se encuentra al borde, en permanente amenaza de caída. Escribe para contener el miedo, para dominarlo. Escribe para nombrar lo innombrable. </p>
<p class="highlight">Hay, en sus primeros versos, una furia mezclada con desconcierto; un temblor y un silencio que es roto solo por poemas breves, incisivos, dolorosos como los segundos que siguen al encuentro con lo nefando.</p>
<p>«Solo en medio del horror el amor es cierto»<sup>4</sup>. El poema es una boca que lame la herida; la palabra es el auxilio para un dolor que ya no es solo propio, sino de un conjunto que parte de la familiaridad —las víctimas y cómplices de los horrores secretos que nacen en el hogar: «¿Puedes ver a mis hermanas hechas añicos acantilarse entre mis dedos?»<sup>5</sup>— y se extiende hacia un <i>nosotras</i> que crece, se hincha y palpita: «Es urgente apretarnos mutuamente / dejar nuestras huellas en la carne de las otras / por si alguna de nosotras se pierde»<sup>6</sup> </p>
<p>Escribe una voz ahogada que enuncia «y corriste alrededor de mi cuello y me llamaste puta»<sup>7</sup>, haciendo uso de la misma metáfora que escoge Chimamanda Ngozi Adichie, desde otro fin del mundo, para describir aquella opresión constante que nos recuerda que no somos libres: «Por la noche algo se enroscaba alrededor de tu cuello. Algo que casi te asfixiaba antes de que te quedaras dormida»<sup>8</sup>. Escribe una mujer que es, a la vez, todas las mujeres. Escribe un cuerpo que abraza y se funde con otros cuerpos en una respiración colectiva, en un ritmo que se vuelve una danza. Se vuelve galope que acelera y se desboca. Éxtasis, júbilo, deseo. </p>
<p class="highlight">El lenguaje como isla distante, incomunicada; un escenario que remite a la infancia en el paisaje insular, a la dicotomía entre el límite-frontera y el horizonte azul infinito.</p>
<p>Dice Lorrie Moore: «La lucha principal de todos los escritores es con la danza y las limitaciones del lenguaje: ser digno de su textura, pero hacerlo sin miedo. Se debe arrojar todo lo que se es al lenguaje»<sup>9</sup>. En su poesía, Yuliana Ortiz Ruano se arroja al lenguaje cargada de todo lo que la hace ella; cabalga en su musicalidad con la ligereza de una hoja al viento, libre de falsas solemnidades, con la sinceridad de quien escribe para reconocerse y encontrarse. Sus versos no se empañan con la perfección artificial que quien busca ser leído y aclamado. La poeta se vierte en la página, se desangra en la tinta, en versos que rompen los moldes y las estructuras en un desfogue de lenguaje vivo. Leerla es replicar la sensación de la caída irrefrenable al vacío. La boca que es el poema se precipita, se lanza en un ritmo frenético que vomita verdad e impacta como el golpe de las olas. Rompe el silencio que aísla a la boca y deriva en locura. </p>
<p>Nombrar el dolor propicia su metamorfosis en canto a la vida. </p>
<p>El poema se transforma, así, en ritual que celebra el nacimiento y también la muerte como parte del ciclo natural, del eterno retorno al origen. La voz poética vuelve al agua, al vientre materno que no es más que otro pequeño mar. Se entrega a la furia de las olas y se deja purificar las heridas con la sal: </p>
<p class="p-all-init-space">Te digo que no hay mejor lugar que el mar para nacernos otra vez / […] No hay mejor lugar que el mar para vernos morir <br/>
[…]<br/>
Te digo que sí / que el mar es la única madre nuestra / corazón salino capaz de acogernos a todos<sup>10</sup></p>
<p>El agua es, a la vez, puente y abismo entre las partes de ese continente que son los afectos, esa Pangea dividida y separada por millas de mar. La voz que canta es hija del agua y es fuente; es vientre que acoge nueva vida: «Yo elegí ser agua / tierna agua niña de sal y arena / capaz de acoger entre mis vientres a todas las mantarrayas del Pacífico»<sup>11</sup>. En el ciclo eterno de nacimiento-muerte-renacimiento, la boca del poema abre sus fauces y empieza a engullirse por la cola, uroboro inflamado e infinito. «La palabra se consume a sí misma», escribía Sylvia Plath hace 60 años<sup>12</sup>. Ortiz Ruano comprende que, al final, la poesía es un impulso caníbal. El deseo como acto caníbal. El amor como acto caníbal: «Soy una manada que se devora a sí misma en un acto involutivo».</p>
<p class="highlight">Escribe un cuerpo que abraza y se funde con otros cuerpos en una respiración colectiva, en un ritmo que se vuelve una danza. </p>
<p><i>Archivo de la desobediencia</i>, selección que recoge textos de sus dos libros publicados y una amplia colección de poemas inéditos, es el legado prematuro de una voz que ha logrado quebrar los límites de una localidad invisible, cuyo eco es capaz de atravesar océanos. Esta breve muestra poética cierra con una sentencia: «El mundo es un gran tejido donde todos agonizamos»<sup>13</sup>. En estas páginas hay una potencia que pone a vibrar las fibras de ese tejido que agoniza en silencio; un grito en tono ascendente que truena hasta romperlas. Yuliana Ortiz Ruano nos entrega, en su poesía, una isla incendiada que deshace el mundo, una boca que nos hinca los dientes y nos engulle.</p>
<div class="ref-container">
<div class="box"></div>
<p class="reference"><sup>1</sup> <i>Aprendiendo a vivir</i> (Ediciones Siruela, 2007) </p>
<p class="reference"><sup>2</sup> Fragmento de «El lenguaje de los huesos», poema inédito compilado en esta antología. </p>
<p class="reference"><sup>3</sup> Clarice Lispector en <i>Aprendiendo a vivir</i> (Ediciones Siruela, 2007). </p>
<p class="reference"><sup>4</sup> En «Bitácora de lo animal», poema que ganó el II Concurso Nacional de Literatura categoría poesía Libre Libro 2019, y forma parte de esta antología.</p>
<p class="reference"><sup>5</sup>En «Canción de amor para un caballo mecánico», del poemario <i>Canciones desde el fin del mundo.</i>
 </p>
<p class="reference"><sup>6</sup> En «Proyecto de poema», que abre la sección de textos inéditos compilados en esta antología.</p>
<p class="reference"><sup>7</sup> En el poema V de <i>Sovoz</i>.</p>
<p class="reference"><sup>8</sup> Chimamanda Ngozi Adichie en el cuento «Algo alrededor de tu cuello», que forma parte de la colección de relatos del mismo nombre (Penguin Random House, 2017).
</p>
<p class="reference"><sup>9</sup> En <i>Sobre escribir</i>, texto de 1994 que Moore incluye en su último libro, <i>A ver qué se puede hacer </i>(2019).</p>
<p class="reference"><sup>10</sup> Fragmentos de «Canción de amor para un caballo mecánico».</p>
<p class="reference"><sup>11</sup> En «Bitácora de lo animal».
 </p>
<p class="reference"><sup>12</sup> Tomado del poema «Viuda», escrito en 1961 y publicado en <i>The collected poems (1981).</i> </p>
<p class="reference"><sup>13</sup> En «Diálogo tercero [Pequeño canto <i>new wave</i>]»</p>
</div>
`;