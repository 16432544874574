export const criticalStudy3 = `
<h2>Especímenes:<br/> <small>perderse en una casa de espejos</small></h2>
<p class="author-name">Por Yanko Molina</p>
<p class="subtitle">Acerca del fantástico</p>
<p>Quizá lo que caracterice con mayor precisión a los cuentos de Marcela Ribadeneira (Quito, 1982) sea la ambigüedad: la capacidad de introducir la duda en sus lectores y que estos no puedan desembarazarse de ella incluso una vez que han terminado su lectura. Esta actitud del dispositivo lector se da por la continua dubitación entre la posibilidad de que lo narrado pertenezca al terreno de lo real o, por el contrario, se sitúe en la esfera de lo imposible, de lo milagroso.</p>
<p>Si seguimos a Tzvetan Todorov<sup>1</sup>, estaríamos en el terreno de lo fantástico. El teórico búlgaro enuncia tres características para poder hablar de este género: </p>
<p class="p-all-init-space">Estamos ahora en condiciones de precisar y completar nuestra definición de lo fantástico. Este exige el cumplimiento de tres condiciones. En primer lugar, es necesario que el texto obligue al lector a considerar el mundo de los personajes como un mundo de personas reales, y a vacilar entre una explicación natural y una explicación sobrenatural de los acontecimientos evocados. Luego, esta vacilación puede ser sentida también por el personaje […], el lector real se identifica con el personaje. Finalmente, es importante que el lector adopte una determinada actitud frente al texto: deberá rechazar la interpretación alegórica como la interpretación poética.</p>
<p>En los cuentos de <i>Especímenes</i> se cumplen las tres premisas. Los ambientes de Ribadeneira son espacios naturales, que podrían desprenderse de nuestra realidad inmediata. Nada, en principio, sorprende en ellos. Los personajes, asimismo, no son muy diferentes de sus posibles lectores, de manera que la identificación, al menos parcial, es sencilla. Todo esta configuración hace que nos distanciemos tanto de la voz alegórica (la de las fábulas, por ejemplo), como de la voz lírica. </p>
<p>Sin embargo, en este entorno aparentemente natural, suceden, de repente, acontecimientos inexplicables, que desconciertan al protagonista —y con él al lector—: un aguacate toma vida, una mujer se desprende de todos sus recuerdos, un castillo de naipes es capaz de detener el tiempo… Todorov opone lo fantástico por un lado a lo extraño (propio de la narrativa policial), y por otro a lo maravilloso (propio del cuento de hadas). Los hechos narrados no se explican, pues los personajes permanecen tan extrañados como el lector, pero tampoco podemos atribuirlos al milagro. La dubitación entre una explicación realista (el protagonista está perdiendo el juicio, o está drogado, o alucina) y la posibilidad de la irrupción de lo sobrenatural se mantiene latente a lo largo de toda la trama. </p>
<p>De ahí la ambigüedad, la confusión del lector en el momento de explicar los acontecimientos del cuento. De ahí también el asombro, el desconcierto, la sorpresa, el encanto. </p>

<p class="subtitle">El terror, el cuerpo</p>
<p>Dentro del gran universo de la fantástico, existen varias formas de explotarlo, según la impresión que genere la duda en el lector. Al leer los cuentos de Marcela Ribadeneira, al desconcierto ante lo inexplicable se suma el miedo. </p>
<p>La sensación de terror se da por la posibilidad de que lo monstruoso surja de lo cotidiano y, sobre todo, por la posibilidad de que el propio protagonista de la ficción —y por tanto el lector que se ha identificado con él— adquiera características monstruosas. </p>
<p>Entre los principales arquetipos de lo monstruoso se encuentran los híbridos animal-hombre (hombre lobo), los muertos vivientes (Drácula, Frankenstein) y los mutilados (el fantasma de la Ópera). Todos estos arquetipos están basados en la degradación del cuerpo físico, que les arranca —en la forma de ver tradicional— la condición de lo humano. En el universo ficcional de Marcela Ribadeneira, el cuerpo mutilado, deforme o que se transforma termina creando monstruos a partir de personajes que pueden identificarse con el lector. </p>
<p class="highlight">De ahí la ambigüedad, la confusión del lector en el momento de explicar los acontecimientos del cuento. De ahí también el asombro, el desconcierto, la sorpresa, el encanto. </p>
<p>El procedimiento que se lleva a cabo en los cuentos de Ribadeneira es realizar una presentación del protagonista en escenarios cotidianos, para luego irnos internando en el fantástico cuando el personaje empieza a transformar su propio cuerpo, o es testigo de la transformación de otros. Este proceso es evidente en cuentos como «Matrioskas», «Héctor» o «La venia», pero aparece también como parte de «La constelación del reloj de arena», «Golems» o «El seto».</p>
<p>En «Matrioskas», por ejemplo, la protagonista, por consejo de su psiquiatra, comienza a desprenderse de todo lo que le es accesorio, buscando su yo más puro. Frente al espejo, empieza por el maquillaje, lo que resulta perfectamente normal y comprensible, pero poco a poco la búsqueda de su «matrioska alfa» se vuelve más insólita, más delirante, hasta que emerge el monstruo. ¿Estamos solamente ante un delirio? La pista de que la protagonista ha acudido a un psiquiatra podría hacernos pensar que sí, pero la total coherencia de la narración hace que dudemos…</p>
<p>Esta presencia de lo corporal como disparador de las transformaciones de los personajes hacia lo monstruoso se vuelve explícita en el cuento que lleva por título precisamente «El cuerpo»:</p>
<p class="p-all-init-space">Un cuerpo es una funda. Es cáscara, membrana. Es contenido, es partes. Un cuerpo es un circuito, una máquina, un hábitat de otros organismos. <b>Un cuerpo se rompe, se rasga, se contamina, se oxida.</b> Un cuerpo es una olla de presión, un banco de información genética. Un abismo. Es laboratorio, es selva, es aliño para el suelo. Un cuerpo es el lugar donde uno muere. […] Un cuerpo es una geografía imaginaria. Es la aniquilación de la inexistencia. Es la encarnación del aire. <b>Un cuerpo es el vacío convertido en grito.</b><sup>2</sup> </p>

<p class="subtitle">La familia </p>
<p>Otro elemento importante, tanto porque refuerza la cotidianidad como porque colabora para la creación del desconcierto ante la emergencia de lo monstruoso, es la constante referencia a las relaciones familiares. Nuevamente, son relaciones donde lo que más resalta es la ambigüedad. Los personajes disfrutan de una familia aparentemente armoniosa, pero pronto empiezan a surgir pistas que hacen pensar en que algo extraño está sucediendo. Eso puede verse en cuentos como «Héctor», «El seto» o «Golems».</p>
<p>En «Héctor», por ejemplo, la relación entre una madre y su hija se ve interrumpida por la irrupción de un hombre al cual la niña teme. Sin embargo, en algún momento del cuento, el lector, junto con la protagonista, comienza a dudar de la identidad del extraño. Nada está claro, pero parece que el intruso forma parte de la relación entre la mujer y su hija. La ambigüedad refuerza el desconcierto, la duda, el temor por lo que pueda pasar con una niña que de repente se nos ha vuelto próxima.</p>
<p>A veces, los motivos de los cuentos se van confundiendo, surgen unos de otros, se entrecruzan acentuando la sensación de desconcierto… En «Golems» está el monstruo, pues la pareja protagonista busca hacer surgir la vida de un objeto inanimado; al mismo tiempo, la relación de la pareja va evolucionando a lo largo del cuento, y vemos surgir los conflictos en medio de la aparente armonía; además, el motivo del cuerpo, especialmente del cuerpo femenino, también está presente en forma metafórica: la protagonista se muestra indignada ante la imposibilidad de ser ella la dadora de vida. El rol de madre le ha sido arrebatado y eso la ha excluido de la pareja.</p>
<p>Ese papel ambiguo de lo femenino como motor de la vida y, por tanto, también de la creación se manifiesta de forma más o menos evidente en al menos otro cuento de este volumen: «La constelación del reloj de arena».</p>

<p class="subtitle">El lenguaje</p>
<p>Pero la construcción de los cuentos de Marcela Ribadeneira no solo crea la atmósfera a través de los espacios o los personajes. El fantástico, en toda su ambigüedad, surge directamente del lenguaje, de las palabras que escoge y los recursos retóricos que utiliza.</p>
<p>El narrador, no importa si relata desde la primera persona o desde la omnisciencia, siempre está plagado de dudas. Las comparaciones —metáforas, símiles— abundan y a veces resultan insólitas. Las enumeraciones se acumulan una sobre otra de manera que desconciertan en su abundancia. Las imágenes resultan ambiguas.</p>
<p class="highlight">A veces, los motivos de los cuentos se van confundiendo, surgen unos de otros, se entrecruzan acentuando la sensación de desconcierto</p>
<p>El desconcierto no solo se produce por lo que se narra, sino también por la forma en que se narra:</p>
<p class="p-all-init-space">La oreja del gato, con sus ondulaciones carnosas, es el interior de una ostra que se abre en tirabuzones al mundo. La luz de la tarde entra en la oreja y en los huesos de la escalera de ingreso de la casa abandonada, donde el gato retoza todas las tardes.
Si le preguntan de qué color es el gato a la mujer del edificio café, la del cuarto piso, la que barre el balcón en bata, tal vez diga que es crema, como los pancakes que prepara para sus dos nietas, que no tienen gargantas sino bocinas acústicas portátiles que ponen nerviosos a los perros y a las palomas del barrio. Para el conductor del camión del gas será amarillo, como la luz de los semáforos, como la pintura de los taxis que anidan en las oficinas de la cooperativa barrial. Pero muchos otros ni siquiera ven al gato ni a las palomas ni al conductor del camión del gas ni a la señora en bata del edificio café. Muchos ni siquiera ven las nubes.<sup>3</sup> </p>

<p class="subtitle">Una generación</p>
<p>Marcela Ribadeneira proviene de una tradición que en Ecuador se remonta al siglo XIX. Aunque el realismo ha sido predominante en la literatura del país, siempre ha habido disidencias que se han deslizado hacia lo fantástico. Ahora, sin embargo, parece ser una corriente importante, y la autora de <i>Especímenes</i> no está sola en sus exploraciones de lo fantástico y del terror. Vale la pena nombrar algunas otras que también exploran el tema, por si algún lector está interesado en continuar su búsqueda dentro de la temática: Mónica Ojeda (<i>Mandíbula, Las voladoras</i>), Sandra Araya (<i>La familia del Dr. Lehman, Un suceso extraño</i>), Solange Rodríguez (<i>La primera vez que vi un fantasma</i>), María Fernanda Ampuero (<i>Sacrificios humanos</i>). </p>
<div class="ref-container">
<div class="box"></div>
<p class="reference"><sup>1</sup> Todorov, Tzvetan. <i>Introducción a la literatura fantástica</i>. Premia: Ciudad de México, 1981.</p>
<p class="reference"><sup>2</sup> Ribadeneira, Marcela. «El cuerpo». En <i>Especímenes</i>. Quito: La Caracola Editores, 2021. Los resaltados son añadidos. </p>
<p class="reference"><sup>3</sup> Ribadeneira, Marcela. «La venia». En <i>Especímenes</i>. Quito: La Caracola Editores, 2021. </p>
</div>
`