import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';


import { shortRoutes, genres } from '../../utils/constants'
import { authors } from '../../utils/data'

import './genre.scss'

export const Genre = () => {
    const history = useHistory();
    const { idGenre } = useParams();
    const [currentGenre, setCurrentGenre] = useState()

    const [genreAuthors, setGenreAuthors] = useState()


    useEffect(() => {
        if (idGenre) {
            const current = genres.find(genre => genre.idGenre == idGenre)
            setCurrentGenre(current)
            ReactGA.pageview('Género ' + current.name);

            const filteredAuthors = authors.filter(author => author.bookGenre == idGenre)
            setGenreAuthors(filteredAuthors)
        }
    }, [idGenre])

    const goToPage = (authorId) => {
        let route = shortRoutes.author;

        history.push(`${route}${authorId}`);

    }

    return (
        <div className='screen-genre'>
            <div className={`genre-title genre${idGenre}`} >
                <div className='title-line'></div>
                <h2>
                    {currentGenre && currentGenre.name}
                </h2>
                <div className='gen-lineas'></div>
            </div>
            
            <div className='container genre-author-box'>
                {genreAuthors && genreAuthors.map((author, index) => {
                    return (
                        <div key={"genre-author-" + index} className='author-genre' onClick={() => goToPage(author.id)}>
                            <img src={`/images/${author.imgGenre}`} alt={currentGenre.name + " " + author.name} />
                            <span className='author-name'>{author.name}</span>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}
