import React from 'react'

import logo3 from './../../assets/footer/logo3.png';

import './footer.scss'

export const Footer = () => {
    return (
        <div className="footer d-flex justify-content-center align-items-center">
            {/* <img src={logo1} alt="Ministerio de Cultura y Patrimonio" title="Ministerio de Cultura y Patrimonio" />
            <img src={logo2} alt="Instituto de Fomento a la Creatividad y la Innovación" title="Instituto de Fomento a la Creatividad y la Innovación" /> */}
            <img src={logo3} alt="La Caracola Editores" title="La Caracola Editores" />
        </div>
    )
}
