import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";

import { BackButton } from '../ui/elements/BackButton'

import { appRoutes, shortRoutes } from '../../utils/constants'
import { authors } from '../../utils/data'
import { criticalStudy1 } from '../../utils/ec_1'
import { criticalStudy2 } from '../../utils/ec_2'
import { criticalStudy3 } from '../../utils/ec_3'
import { criticalStudy4 } from '../../utils/ec_4'
import { criticalStudy5 } from '../../utils/ec_5'
import { criticalStudy6 } from '../../utils/ec_6'

import './criticalStudy.scss'

const criticalData = [
    criticalStudy1,
    criticalStudy2,
    criticalStudy3,
    criticalStudy4,
    criticalStudy5,
    criticalStudy6
];

export const CriticalStudy = () => {
    const history = useHistory();
    const { idAuthor } = useParams();
    const [currentAuthor, setCurrentAuthor] = useState()
    const [currentStudy, setCurrentStudy] = useState()

    // estado de estudio critico
    const [currentStudyPdf, setCurrentStudyPdf] = useState()


    useEffect(() => {
        if (idAuthor) {
            const currentAuthor = authors.find(author => author.id === idAuthor)
            const isPdf = currentAuthor.isCriticalStudyPdf
            setCurrentAuthor(currentAuthor)
            setCurrentStudy(criticalData[parseInt(currentAuthor.id) - 1])
            setCurrentStudyPdf(isPdf)

            ReactGA.pageview('Estudio Critico ' + currentAuthor.name);
        }
    }, [idAuthor])

    const goToPage = (authorId, page) => {
        let route = shortRoutes.author;
        switch (page) {
            case 1:
                route = shortRoutes.criticalStudy;
                break;
            case 2:
                route = shortRoutes.interview;
                break;
            case 3:
                route = shortRoutes.book;
                break;
            case 4:
                route = shortRoutes.teachers;
                break;
            default:
                break;
        }
        history.push(`${route}${authorId}`);

    }

    return (
        <>
            {
                idAuthor ?
                    <div className="screen-critical-study">
                        {
                            currentAuthor &&
                            <div className="container-fluid">
                                <BackButton />
                                <div className="row">
                                    <div className="col">
                                        <p className="text-center title m-0">
                                            Estudio crítico
                                            <br />
                                            <small>
                                                sobre la obra de
                                            </small>
                                        </p>
                                        <h1 className="text-center">
                                            {currentAuthor.name}
                                        </h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-center">
                                        {
                                            currentStudyPdf ?

                                                <div className="row critical-study-width">
                                                    <div className="col d-flex justify-content-center">
                                                        <div className="book-box mt-5">
                                                            <iframe style={{ width: "100%", height: "100%" }} title="estudio-critico" src={currentAuthor.criticalStudyPdf} seamless="seamless" scrolling="no" frameBorder="0" allowtransparency="true" allowFullScreen={true} ></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="critical-study-container" dangerouslySetInnerHTML={{ __html: currentStudy }}>
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                                <div className="row row-book-link py-5 mt-5">
                                    <div className="d-flex justify-content-center">
                                        {
                                            (currentAuthor && currentAuthor.hasInterview) &&
                                            <div className="d-flex flex-column justify-content-center align-items-center mx-5">
                                                <img className="hex-img-link mini" src={`/images/${currentAuthor.imgInterviewHex}`} alt="Entrevista" title="Entrevista" />
                                                <button className="btn btn-outline-dark mt-3" onClick={() => goToPage(currentAuthor.id, 2)}>
                                                    Entrevista
                                                </button>
                                            </div>
                                        }
                                        <div className="d-flex flex-column justify-content-center align-items-center mx-5">
                                            <img className="hex-img-link mini" src={`/images/${currentAuthor.imgBookHex}`} alt="Ver libro" title="Ver libro" />
                                            <button className="btn btn-outline-dark mt-3" onClick={() => goToPage(currentAuthor.id, 3)}>
                                                Libro
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    currentAuthor.questions.length &&
                                    <div className="row row-book-link py-5">
                                        <div className="col d-flex flex-column justify-content-center align-items-center">
                                            <img className="hex-img-link mini" src={`/images/${currentAuthor.imgTeachersHex}`} alt="Actividades de mediación de lectura" title="Actividades de mediación de lectura" />
                                            <button className="btn btn-outline-dark mt-4" onClick={() => goToPage(currentAuthor.id, 4)}>
                                                Actividades de mediación de lectura
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        }
                    </div>
                    :
                    <Redirect to={appRoutes.home} />
            }
        </>
    )
}
