import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';

import { BackButton } from '../ui/elements/BackButton'

import { appRoutes, shortRoutes } from '../../utils/constants'
import { authors } from '../../utils/data'
import './book.scss'

export const Book = () => {
    const history = useHistory();
    const { idAuthor } = useParams();
    const [currentAuthor, setCurrentAuthor] = useState()


    useEffect(() => {
        if (idAuthor) {
            const currentAuthor = authors.find(author => author.id === idAuthor)
            setCurrentAuthor(currentAuthor)

            ReactGA.pageview('Libro ' + currentAuthor.name);
        }
    }, [idAuthor])

    const goToPage = (authorId, page) => {
        let route = shortRoutes.author;
        switch (page) {
            case 1:
                route = shortRoutes.criticalStudy;
                break;
            case 2:
                route = shortRoutes.interview;
                break;
            case 3:
                route = shortRoutes.book;
                break;
            case 4:
                route = shortRoutes.teachers;
                break;
            default:
                break;
        }
        history.push(`${route}${authorId}`);

    }

    return (
        <>
            {
                idAuthor ?
                    <div className="screen-book">
                        {
                            currentAuthor &&
                            <div className="container-fluid">
                                <BackButton />
                                <div className="row">
                                    <div className="col">
                                        <p className="text-center title m-0">Libro</p>
                                        <h1 className="text-center">
                                            {currentAuthor.name}
                                        </h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-center">
                                        <div className="book-box mt-5">
                                            <iframe style={{ width: "100%", height: "100%" }} title="libro" src={currentAuthor.bookLink} seamless="seamless" scrolling="no" frameBorder="0" allowtransparency="true" allowFullScreen={true} ></iframe>
                                        </div>
                                    </div>
                                </div>

                                {
                                    currentAuthor.isUDLACoedition &&
                                    <div className='udla-container'>
                                        <p className='udla-text'>Esta publicación es una coedición con </p>
                                        <img className="udla-logo" src="./../../images/logo-udla.png" alt="Logo UDLA" title="Logo UDLA" />
                                    </div>
                                }
                                {
                                    (currentAuthor.hasCriticalStudy || currentAuthor.hasInterview || currentAuthor.questions.length) &&
                                    <div className='footer-book'> 
                                    <div className="row row-book-link py-5 ">
                                        <div className="d-flex justify-content-center">
                                            {
                                                currentAuthor.hasCriticalStudy &&
                                                <div className="d-flex flex-column justify-content-center align-items-center mx-5">
                                                    <img className="hex-img-link mini" src={`/images/${currentAuthor.imgCriticalStudyHex}`} alt="Estudio crítico" title="Estudio crítico" />
                                                    <button className="btn btn-outline-dark mt-3" onClick={() => goToPage(currentAuthor.id, 1)}>
                                                        Estudio crítico
                                                    </button>
                                                </div>
                                            }

                                            {
                                                (currentAuthor && currentAuthor.hasInterview) &&
                                                <div className="d-flex flex-column justify-content-center align-items-center mx-5">
                                                    <img className="hex-img-link mini" src={`/images/${currentAuthor.imgInterviewHex}`} alt="Entrevista" title="Entrevista" />
                                                    <button className="btn btn-outline-dark mt-3" onClick={() => goToPage(currentAuthor.id, 2)}>
                                                        Entrevista
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    {
                                        currentAuthor.questions.length &&
                                        <div className="row row-book-link py-5">
                                            <div className="col d-flex flex-column justify-content-center align-items-center">
                                                <img className="hex-img-link mini" src={`/images/${currentAuthor.imgTeachersHex}`} alt="Actividades de mediación de lectura" title="Actividades de mediación de lectura" />
                                                <button className="btn btn-outline-dark mt-3" onClick={() => goToPage(currentAuthor.id, 4)}>
                                                    Actividades de mediación de lectura
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                }

                            </div>
                        }
                    </div>
                    :
                    <Redirect to={appRoutes.home} />
            }
        </>
    )
}
