import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';

import { BackButton } from '../ui/elements/BackButton'

import { appRoutes, shortRoutes } from '../../utils/constants'
import { authors } from '../../utils/data'
import './author.scss'

export const Author = () => {

    const history = useHistory();
    const { idAuthor } = useParams();
    const [currentAuthor, setCurrentAuthor] = useState()

    useEffect(() => {
        if (idAuthor) {
            const currentAuthor = authors.find(author => author.id === idAuthor)
            setCurrentAuthor(currentAuthor)
            ReactGA.pageview('Autor ' + currentAuthor.name);
        }
    }, [idAuthor])

    const goToPage = (authorId, page) => {
        let route = shortRoutes.author;
        switch (page) {
            case 1:
                route = shortRoutes.criticalStudy;
                break;
            case 2:
                route = shortRoutes.interview;
                break;
            case 3:
                route = shortRoutes.book;
                break;
            case 4:
                route = shortRoutes.teachers;
                break;
            default:
                break;
        }
        history.push(`${route}${authorId}`);

    }

    return (
        <>
            {
                idAuthor ?
                    <div className="screen-author">
                        {
                            currentAuthor &&
                            <div className="container-fluid">
                                <BackButton />
                                <div className="row row-bio">
                                    <div className="col-12 col-md-6 d-flex flex-column align-items-end">
                                        <div className="picture-author">
                                            <img alt={currentAuthor.name} src={`/images/${currentAuthor.imgPictureHex}`} />
                                            {
                                                currentAuthor.imgPictureHexRef &&
                                                <span className='rotate-text'>{currentAuthor.imgPictureHexRef}</span>
                                            }
                                        </div>


                                    </div>
                                    <div className="col-12 col-md-6 pe-md-5">
                                        <div className="bio-author" dangerouslySetInnerHTML={{ __html: currentAuthor.bio }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-links">
                                    <div className="green-bar"></div>
                                    <div className="col d-flex justify-content-center">
                                        {
                                            currentAuthor.hasCriticalStudy &&
                                            <div className="hex">
                                                <img className="hex-img-link" src={`/images/${currentAuthor.imgCriticalStudyHex}`} alt="Estudio crítico" title="Estudio crítico" />
                                                <button className="btn btn-outline-secondary mt-4" onClick={() => goToPage(currentAuthor.id, 1)}>
                                                    Estudio crítico
                                                </button>
                                            </div>
                                        }

                                        {
                                            (currentAuthor && currentAuthor.hasInterview) &&
                                            <div className="hex">
                                                <img className="hex-img-link" src={`/images/${currentAuthor.imgInterviewHex}`} alt="Entrevista" title="Entrevista" />
                                                <button className="btn btn-outline-secondary mt-4" onClick={() => goToPage(currentAuthor.id, 2)}>
                                                    Entrevista
                                                </button>
                                            </div>
                                        }
                                        <div className="hex">
                                            <img className="hex-img-link" src={`/images/${currentAuthor.imgBookHex}`} alt="Ver libro" title="Ver libro" />
                                            <button className="btn btn-outline-secondary mt-4" onClick={() => goToPage(currentAuthor.id, 3)}>
                                                Libro
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    currentAuthor.questions.length > 0 &&

                                    <div className="row row-book-link py-5">
                                        <div className="col d-flex flex-column justify-content-center align-items-center">
                                            <img className="hex-img-link mini" src={`/images/${currentAuthor.imgTeachersHex}`} alt="Actividades de mediación de lectura" title="Actividades de mediación de lectura" />
                                            <button className="btn btn-outline-dark mt-4" onClick={() => goToPage(currentAuthor.id, 4)}>
                                                Actividades de mediación de lectura
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    :
                    <Redirect to={appRoutes.home} />
            }
        </>
    )
}
