export const authors = [
    {
        id: "1",
        name: "Gabriela Vargas Aguirre",
        bookName: "Antología Personal",
        orderName: 14,
        orderBook: 14,
        bookLink: "https://online.anyflip.com/ttorr/vlrk/index.html",
        bookGenre: 1,
        isUDLACoedition: false,
        bio: "<p><strong>Gabriela Vargas Aguirre</strong><br/><br/> Es una poeta nacida en Guayaquil, Ecuador, en 1984. Mención en el V Premio Nacional de Poesía Joven Ileana Espinel Cedeño. Ganadora de los Fondos Concursables del Ministerio de Cultura y Patrimonio 2016 – 2017 con los que publicó su primer poemario <i>La Ruta de la Ceniza</i> con la editorial ecuatoriano – argentina La Caída. Ha participado en la Feria Internacional de Quito en los años 2012, 2015 y 2017, el Festival Desembarco Poético (Guayaquil 2013, 2014, 2015), el VI Festival de Poesía de Lima, el Festival Latinoamericano de Poesía Tea Party en Chile, el Festival Otra Orilla (Guayaquil; 2015 y 2017), la Feria del Libro Independiente de la Universidad San Francisco de Quito (2017) y el festival Kanibal Urbano (Quito; 2018). Paricipó de las X Jornadas de Poesía de la ciudad de Bogotá (2018). Aparece en publicaciones y antologías como <i>Memorias del Festival Internacional Desembarco Poético</i> (Rastro de la Iguana; 2012, 2013, 2014), <i>Bandada: Actualidad de la Poesía Ecuatoriana</i> (Campaña de Lectura Eugenio Espejo; 2014), <i>Mujeres que Hablan</i> (Dirección de Cultura de Pichincha; 2015), <i>Antología del Tea Party, Muestra Dinámica de Poesía Latinoamericana</i> (Cinosargo; Chile 2016) y <i>País imaginario. Escrituras y transtextos. Poesía latinoamericana 1980-1992</i> (Ay del seis; España 2018).</p>",
        imgPictureHex: "picture1.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH1.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "interview1.png",
        imgBookHex: "book1.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: false,
        criticalStudyPdf: "",
        hasInterview: true,
        hasCriticalStudy: true,
        interviews: [
            {
                text: "1. ¿Cuándo te iniciaste en el mundo de la escritura de poesía y por qué se ha vuelto tan importante para ti? ",
                video: "https://www.youtube.com/embed/BwYb8-_C7aQ"
            },
            {
                text: "2. En la escritura de poesía, existe un ritmo que se va articulando a lo largo de todo el texto. ¿Cómo observas el tema de la musicalidad en tu escritura creativa? ",
                video: "https://www.youtube.com/embed/F4S34Z3ejLI"
            },
            {
                text: "3. ¿Cuáles son las principales influencias literarias que han marcado tu camino creativo —principales lecturas-?",
                video: "https://www.youtube.com/embed/xWXamhR4PGw"
            },
            {
                text: "4. ¿Qué imágenes te obsesionan?, ¿cuáles son las imágenes que atormentan tu escritura y se convierten en el motor de la misma?",
                video: "https://www.youtube.com/embed/5LeLpzSVI_o"
            },
            {
                text: "5. ¿Cómo observas, en tu escritura creativa, la articulación de un eje madre-hija-madre? ¿Cómo estás relaciones han influido en tu escritura?",
                video: "https://www.youtube.com/embed/u2fsIvE1TQI"
            },
            {
                text: "6. ¿Qué significados posee “la casa” —siendo el útero la primera de las casas— en la composición simbólica de tu mundo? ",
                video: "https://www.youtube.com/embed/s8ojP9ifzMg"
            },
            {
                text: "7. ¿Cómo crees que ha marcado tu ejercicio escritural la difícil coyuntura actual de distanciamiento social?",
                video: "https://www.youtube.com/embed/wHwHfVyrcoc"
            },
            {
                text: "8. Tus libros han merecido reconocimientos importantes. ¿Qué significado tienen para ti los premios literarios o los fondos concursables?",
                video: "https://www.youtube.com/embed/OD1gjGt39vk"
            },
            {
                text: "9. ¿Cómo observas a la generación contemporánea (a la que perteneces) de escritores y escritoras del Ecuador?",
                video: "https://www.youtube.com/embed/_3vBiPOd7i4"
            }
        ],
        questions: [
            "Realiza, utilizando aplicaciones de internet, una nube de palabras con los principales conceptos que comprendiste del estudio introductorio <i>Antología Personal</i>.",
            "Construye un avatar de la autora, utilizando herramientas digitales, donde el avatar comente sus principales creencias a propósito de la literatura. Las puedes obtener de mirar su entrevista a profundo. ",
            "Selecciona, del libro de Gabriela Vargas Aguirre, 10 términos que no te sean conocidos. Después realiza un glosario con los mismos, investigando su significado en internet.",
            "Utilizando cualquier tipo de herramienta digital que te permita realizar infografías, realiza una que sintetice lo dicho por la autora en su libro.",
            "Selecciona un poema de la autora y marca, con diferentes colores, cuáles son las ideas principales de la misma y cuáles las secundarias. ",
            "Realiza un video de ti mismo (no más de 40 segundos), explicando a tus compañeros, por qué recomiendas leer a Gabriela Vargas Aguirre.",
            "Graba un audio tuyo, leyendo el poema de Gabriela Vargas Aguirre que más te haya gustado.",
            "Finalmente, escribe un ensayo en el que indiques cuáles son los elementos que te gustaron de la lectura de Gabriela Vargas Aguirre y cuáles no."
        ]
    },
    {
        id: "2",
        name: "Juan José Rodinás",
        bookName: "Amplitud modulada",
        orderName: 10,
        orderBook: 10,
        bookLink: "https://online.anyflip.com/ttorr/obgg/index.html",
        bookGenre: 1,
        isUDLACoedition: false,
        bio: "<p><strong>Juan José Rodinás</strong> <br/><br/>Es un poeta nacido en Ambato, Ecuador, en 1979. Ha publicado <i>Los rastros</i> (2006), <i>Viaje de la mansedumbre</i> (2009), <i>Barrido de campo</i> (2010), <i> Cromosoma</i> (2011), <i> Los páramos inversos</i> (2014), <i>Cuaderno de Yorkshire</i> (Premio Internacional de Poesía Margarita Hierro, <i>Pre-Textos</i>, 2018 y Premio Jorge Carrera Andrade), y <i>Yaraví para cantar bajo los cielos del norte (Biografía no autorizada de un Banksy sudamericano) </i> (Premio Casa de las Américas, 2019).</p>",
        imgPictureHex: "picture2.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH2.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "interview2.png",
        imgBookHex: "book2.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: false,
        criticalStudyPdf: "",
        hasInterview: true,
        hasCriticalStudy: true,
        interviews: [
            {
                text: "1. En tus primeros libros —<i>Los rastros, Viaje a la mansedumbre</i>— había una constante preocupación por el silencio. ¿Sigues pensando que es importante en la poesía?",
                video: "https://www.youtube.com/embed/sQYMoDhBX4Y"
            },
            {
                text: "2. Luego, más bien las páginas se ven desbordadas por el lenguaje, hay un cambio hacia lo que podría acercarse al barroco… ¿Hay un cambio en tu experiencia vital que coincide con esta explosión de las palabras?",
                video: "https://www.youtube.com/embed/kkCG64iuwJU"
            },
            {
                text: "3. Dentro de tu poesía son muy importantes los referentes culturales. Por supuesto, está presente la poesía, pero también la plástica, la música… ¿Cómo han aportado las distintas manifestaciones artísticas a configurar tu obra?",
                video: "https://www.youtube.com/embed/AojiuiDweJ4"
            },
            {
                text: "4. La geografía, el paisaje, también es importante. ¿Las imágenes del entorno moldean tu voz lírica? ¿cuál es su función dentro de la poesía?",
                video: "https://www.youtube.com/embed/4QePvldVDjY"
            },
            {
                text: "5. Luego, a partir de tu viaje para estudiar fuera de Ecuador, hay un retorno a la contención… ¿Cómo aportó la experiencia de tu vida en Inglaterra para renovar tu obra?",
                video: "https://www.youtube.com/embed/o5oVFgErxiE"
            },
            {
                text: "6. ¿Quién es Dollboy?",
                video: "https://www.youtube.com/embed/hXtiKa5rsMY"
            },
            {
                text: "7. En tu obra, aparecen relaciones de elementos arbitrarios. ¿Sientes una deuda con el surrealismo?",
                video: "https://www.youtube.com/embed/rCSQE2q6Uco"
            },
            {
                text: "8. También has trabajado en traducción. ¿Cuál es tu relación con la poesía en lengua inglesa?",
                video: "https://www.youtube.com/embed/lrdjG75pC_Y"
            },
            {
                text: "9. ¿Cómo inscribirías tu obra en la poesía ecuatoriana?",
                video: "https://www.youtube.com/embed/u_qBdXhifaU"
            }
        ],
        questions: [
            "Realiza, utilizando aplicaciones de internet, una nube de palabras con los principales conceptos que comprendiste del estudio introductorio escrito por Juan José Rodinás.",
            "Construye un avatar del autor, utilizando herramientas digitales, donde el avatar comente sus principales creencias a propósito de la literatura. Las puedes obtener de mirar su entrevista a profundo. ",
            "Selecciona, del libro de Juan José Rodinás, 10 términos que no te sean conocidos. Después realiza un glosario con los mismos, investigando su significado en internet.",
            "Utilizando cualquier tipo de herramienta digital que te permita realizar infografías, realiza una que sintetice lo dicho por el autor en su libro.",
            "Selecciona un poema del autor y marca, con diferentes colores, cuáles son las ideas principales del mismo y cuáles las secundarias. ",
            "Realiza un video de ti mismo (no más de 40 segundos), explicando a tus compañeros, por qué recomiendas leer a Juan José Rodinás.",
            "Graba un audio tuyo, leyendo el poema de Juan José Rodinás que más te haya gustado.  ",
            "Finalmente, relacionar al autor/a con libros o poemas de otros autores que conozcan; decir en qué se parecen y en qué no"
        ]
    },
    {
        id: "3",
        name: "Marcela Ribadeneira",
        bookName: "Especímenes Antología Personal",
        orderName: 9,
        orderBook: 9,
        bookLink: "https://online.anyflip.com/ttorr/omwo/index.html",
        bookGenre: 2,
        isUDLACoedition: false,
        bio: "<p><strong>Marcela Ribadeneira </strong><br/>(Quito, 1982)<br/><br/>Es escritora, periodista y artista visual. Ha colaborado con revistas como <i>Mundo Diners, SoHo, Ronda, Siente, GK</i>, el periódico del cine Ochoymedio y el diario británico <i>The Guardian</i>. Ha publicado los libros <i>Matrioskas</i> (Cadáver exquisito, 2014) y <i>Golems</i> (El Conejo, 2018), el <i>ebook Borrador final</i> (Suburbano ediciones, 2016) y el cuento ilustrado <i>Héctor</i> (Doble Rostro, 2020). Sus relatos y crónicas han sido antologados en libros como <i>Ciudades visibles</i> (FNPI y editorial RM, 2016), <i>Ecuador cuenta</i> (Del centro editores, 2014), <i>Señorita Satán. Nuevas narradoras ecuatorianas </i> (El Conejo, 2017), <i>Proyecto Carrie</i> (Raíces Latinas, 2021), <i>Visiones ecuatoriales</i> (Teoría Omicrón y Libros Duende, 2021) y <i>Ecuador en corto</i> (Universidad de Zaragoza, 2020).<br/><br/>Su trabajo en collage digital ha ilustrado portadas de libros como <i>La escala humana </i> (Abdón Ubidia, editorial El Conejo), <i>Faltas ortográficas</i> (Eduardo Varas, CCE), <i>Las voladoras</i> (Mónica Ojeda, Páginas de Espuma) y <i>Sacrificios humanos</i> (María Fernanda Ampuero, Páginas de Espuma). También ha sido exhibido en plataformas como Arte Contemporáneo Ecuador, Cultura Colectiva (México), Suburbano (EE.UU.) y la galería Mediaagua.<br/><br/>En 2016 fue parte del grupo Ochenteros: 20 escritores que la Feria Internacional del Libro de Guadalajara seleccionó como nuevas voces de la literatura latinoamericana. </p>",
        imgPictureHex: "picture3.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH3.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "interview3.png",
        imgBookHex: "book3.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: false,
        criticalStudyPdf: "",
        hasInterview: true,
        hasCriticalStudy: true,
        interviews: [
            {
                text: "1. ¿Cómo sientes que han ido cambiando tus procesos de escritura a lo largo de los años?",
                video: "https://www.youtube.com/embed/vII6ori4PCQ"
            },
            {
                text: "2. ¿Qué registros culturales (el cine, la ciencia, las artes plásticas, etc.) sientes que son los que más te influencian a la hora de escribir?",
                video: "https://www.youtube.com/embed/v0Ahnmlx7TQ"
            },
            {
                text: "3. ¿Qué espacio ocupa en tus actividades creativas la escritura de ficción (tomando en cuenta que también has incursionado en otras expresiones artísticas)?",
                video: "https://www.youtube.com/embed/E2Z16p0nL9w"
            },
            {
                text: "4. ¿Cómo observas a la generación contemporánea (a la que perteneces) de escritores y escritoras del Ecuador?",
                video: "https://www.youtube.com/embed/QzknLgVBRwU"
            },
            {
                text: "5. ¿Cuáles son los principales cambios que experimentas en la maduración de tu escritura, de un libro a otro? ",
                video: "https://www.youtube.com/embed/v4QvYWSynRg"
            },
            {
                text: "6. ¿Cuáles son las imágenes reiterativas que vuelven una y otra y otra vez a tu proceso de escritura? ",
                video: "https://www.youtube.com/embed/oSAI1i1ubq4"
            },
            {
                text: "7. ¿Has experimentado con otros géneros?",
                video: "https://www.youtube.com/embed/JCq22kcgf4o"
            },
            {
                text: "8. ¿Cuáles son las principales influencias literarias que han marcado tu camino creativo —principales lecturas-?",
                video: "https://www.youtube.com/embed/Xm4fc8LYZ-A"
            },
            {
                text: "9. Tus cuentos responden al modelo de lo fantástico, mantiene la duda en el lector si lo enunciado es posible o no. ¿Por qué escogiste escribir cuentos fantásticos y no realistas?",
                video: "https://www.youtube.com/embed/gPP-l477N5Y"
            },
            {
                text: "10. Muchos de los cuentos podrían inscribirse en subgéneros de la narrativa —ciencia ficción, terror…— ¿Qué te interesa de este tipo de literatura?",
                video: "https://www.youtube.com/embed/YPY2AFnZDx4"
            },
            {
                text: "11. En varios de tus cuentos, los personajes pertenecen a un entorno familiar, son hijos, parejas, hermanos… Cómo funcionan las relaciones familiares en tu literatura.",
                video: "https://www.youtube.com/embed/IaQWIOH_L4o"
            },
            {
                text: "12. En tus cuentos es clara la importancia del lenguaje. Hay un frecuente despliegue de imágenes, de metáforas. Hay una preocupación por el ritmo. ¿Cuál es la importancia del lenguaje, incluso de la retórica, en tu literatura?",
                video: "https://www.youtube.com/embed/NGpy_-ZeZgI"
            }
        ],
        questions: [
            "Realiza, utilizando aplicaciones de internet, una nube de palabras con los principales conceptos que comprendiste del estudio introductorio.",
            "Construye un avatar de la autora, utilizando herramientas digitales, donde el avatar comente sus principales creencias a propósito de la literatura. Las puedes obtener de mirar su entrevista a profundo. ",
            "Selecciona, del libro de Marcela Ribadeneira, 10 términos que no te sean conocidos. Después realiza un glosario con los mismos, investigando su significado en internet.",
            "Utilizando cualquier tipo de herramienta digital que te permita realizar infografías, realiza una que sintetice lo dicho por un narrador seleccionado de alguno de sus cuentos.",
            "Selecciona un cuento de la autora y grafica la atmósfera o espacio en el que se desarrolla la historia. ",
            "Realiza un video de ti mismo (no más de 40 segundos), explicando a tus compañeros, por qué recomiendas leer a Marcela Ribadeneira.",
            "Graba un audio tuyo, leyendo una parte de un cuento de Marcela Ribadeneira; el que más te haya gustado.  ",
            "Finalmente, hacer una entrevista ficticia a uno de los personajes que más les gustaron, e imaginar las respuestas. "
        ]
    },
    {
        id: "4",
        name: "Mauricio Montenegro Zabala",
        bookName: "Jaulas",
        orderName: 7,
        orderBook: 7,
        bookLink: "https://online.anyflip.com/ttorr/xfks/index.html",
        bookGenre: 2,
        isUDLACoedition: false,
        bio: "<p><strong>Mauricio Montenegro Zabala</strong><br/> (Quito, 1985)<br/><br/> Es licenciado en Comunicación y Literatura por la Pontificia Universidad Católica del Ecuador y tiene estudios de maestría en Literaturas Latinoamericana y Española por la Universidad de Buenos Aires. Ha trabajado como autor, editor, jefe de corrección y corrector de estilo independiente para universidades de posgrado, editoriales, ONG y agencias de publicidad por más de una década. Sus cuentos y reseñas han sido incluidos en antologías, revistas y blogs de Argentina, México, Colombia y Ecuador. </p>",
        imgPictureHex: "picture4.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH4.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "interview4.png",
        imgBookHex: "book4.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: false,
        criticalStudyPdf: "",
        hasInterview: true,
        hasCriticalStudy: true,
        interviews: [
            {
                text: "1. ¿Cómo ha sido el proceso de escritura de tu libro <i>Jaulas</i> (cuánto tiempo duró, qué etapas atravesó)?",
                video: "https://www.youtube.com/embed/yfDyXDd60kQ"
            },
            {
                text: "2. Varios de tus personajes viven una especial condición de soledad. ¿Cómo se relaciona en tu vida la soledad con la escritura literaria?",
                video: "https://www.youtube.com/embed/MhcbV984G9E"
            },
            {
                text: "3. Otra de las temáticas que resaltan en algunas de tus narraciones es una relación conflictiva con el padre. ¿Cómo fue tu estrategia para abordar un motivo tan clásico de un modo tuyo propio?",
                video: "https://www.youtube.com/embed/9WzOHISCIyw"
            },
            {
                text: "4. Dada la presencia que tiene en tus cuentos, ¿qué importancia juega la etapa de la infancia en tu formación sentimental, en tus afectos, como escritor?",
                video: "https://www.youtube.com/embed/YhMcCSCr0lw"
            },
            {
                text: "5. ¿De qué modo vives en tus propios procesos de escritura la relación entre experiencia (o biografía) y ficción?",
                video: "https://www.youtube.com/embed/a6lnKqWOxGw"
            },
            {
                text: "6. ¿Cómo sería la constelación de influencias que han marcado tu escritura de <i>Jaulas</i>?",
                video: "https://www.youtube.com/embed/FZzX1-6paTM"
            },
            {
                text: "7. Tú tienes formación profesional en edición de textos y corrección de estilo; ¿cómo crees que esa experiencia marca tus rutinas de escritura creativa?",
                video: "https://www.youtube.com/embed/fMRsjlaIhBw"
            },
            {
                text: "8. Ya que (en licenciatura y posgrado) has estudiado literatura, ¿cómo sientes que se relacionan en tu ámbito personal las aproximaciones académica y creativa en torno a la escritura?",
                video: "https://www.youtube.com/embed/WOXRR4mlNE4"
            },
            {
                text: "9. ¿Cuáles serían para ti las características esenciales de un cuento, de acuerdo con tu gusto personal?",
                video: "https://www.youtube.com/embed/q2qjq-rNV-I"
            },
            {
                text: "10. ¿Cómo observas a la generación contemporánea (a la que perteneces) de escritores y escritoras del Ecuador?",
                video: "https://www.youtube.com/embed/JzdOiqpPGeo"
            }
        ],
        questions: [
            "Realiza, utilizando aplicaciones de internet, una nube de palabras con los principales conceptos que comprendiste del estudio introductorio.",
            "Construye un avatar del autor, utilizando herramientas digitales, donde el avatar comente sus principales creencias a propósito de la literatura. Las puedes obtener de mirar su entrevista a profundo. ",
            "Selecciona, del libro de Mauricio Montenegro, 10 términos que no te sean conocidos. Después realiza un glosario con los mismos, investigando su significado en internet.",
            "Utilizando cualquier tipo de herramienta digital que te permita crear infografías, realiza una que sintetice lo dicho por un narrador seleccionado de alguno de sus cuentos.",
            "Selecciona un cuento del autor y grafica la atmósfera o espacio en el que se desarrolla la historia. ",
            "Realiza un video de ti mismo (no más de 40 segundos), explicando a tus compañeros, por qué recomiendas leer a Mauricio Montenegro.",
            "Graba un audio tuyo, leyendo una parte de un cuento de Mauricio Montenegro; puede ser el que más te haya gustado.  ",
            "Finalmente, cambia el final de una de las historias y explica tus razones para tu decisión."
        ]
    },
    {
        id: "5",
        name: "Yuliana Ortiz Ruano",
        bookName: "Archivo de la desobediencia",
        orderName: 8,
        orderBook: 8,
        bookLink: "https://online.anyflip.com/ttorr/egkt/index.html",
        bookGenre: 1,
        isUDLACoedition: false,
        bio: "<p><strong>Yuliana Ortiz-Ruano </strong><br/>(Esmeraldas, 1992) <br/><br/>Ha publicado dos libros de poesía: <i>Sovoz</i> (Hanan Harawi, Lima, Perú 2016) y <i>Canciones desde el fin del mundo</i> (Amauta&Yaguar, Buenos Aires, 2018 – Kikuyo Editorial, Quito 2020 – Libero Editorial, Madrid, 2021). <br/><br/>Ha participado en festivales y encuentros literarios en Ecuador, Perú, Colombia, Argentina y Chile. Su trabajo ha obtenido premios como el primer lugar en el Concurso Nacional de Literatura, categoría poesía Libre Libro 2019, y Mención de honor en el concurso nacional Poesía en Paralelo 0 2017. Textos suyos aparecen en revistas y antologías digitales e impresas de México, Argentina, Ecuador, Colombia, Venezuela, España y Portugal.<br/><br/>Fue miembro de los grupos de investigación académica en artes: Trágico y tránsito (Filosofía) y Soltando la palabra, pedagogías subversivas en torno al pensamiento de Juan García (Literatura afrodescendiente y prácticas pedagógicas). Formó parte del grupo de investigadoras de Latinoamérica y el Caribe en el Mapeo de Feminismos Negros en Abya Yala, Re-existencia transfronteriza «entre aquí y allá».<br/>Actualmente investiga la exclusión cartográfica como potencia detonadora de procesos artísticos y lo poético en distintos registros literarios.</p>",
        imgPictureHex: "picture5.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH5.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "interview5.png",
        imgBookHex: "book5.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: false,
        criticalStudyPdf: "",
        hasInterview: true,
        hasCriticalStudy: true,
        interviews: [
            {
                text: "1. ¿Cómo fue tu ingreso a la escritura de poesía?",
                video: "https://www.youtube.com/embed/nXR_6edB0pY"
            },
            {
                text: "2. Los archivos se construyen a partir de eventos. Los que perduran en el tiempo, son aquellos que narran sucesos que marcaron hitos y generaron cambios en la historia. ¿Qué eventos dieron lugar a <i>Archivos de la Desobediencia</i>?",
                video: "https://www.youtube.com/embed/q9ByaPHnmoQ"
            },
            {
                text: "3. Entre los poemas de tu primer libro, escribes “porque yo no tengo patria, / porque mi patria / es el canto primero de mis ancestros, en la isla de mi apellido materno”. ¿Qué hubo detrás de ese impulso hacia la construcción de un mundo propio, de una mitología propia que hurga en el conocimiento ancestral - familiar, y que atraviesa tu obra desde este inicio?",
                video: "https://www.youtube.com/embed/h3RwsC7zNmU"
            },
            {
                text: "4. ¿Dónde se encuentra, para ti, el fin del mundo? ¿Por qué buscar la belleza en lo que arde?",
                video: "https://www.youtube.com/embed/e3OeLD2ShaQ"
            },
            {
                text: "5. Hay en tus versos una relación cíclica entre lo catastrófico (la muerte, la inundación, la desesperanza) y la vuelta al origen (el nacimiento, el canto a la vida). ¿En qué punto de este ciclo sientes que se encuentra ahora tu escritura?",
                video: "https://www.youtube.com/embed/JxuwQ3ev8oc"
            },
            {
                text: "6. ¿Cómo se compaginan los cuestionamientos que dan lugar a tu poesía con aquellos que te haces como mujer, activista e investigadora?",
                video: "https://www.youtube.com/embed/L6RR4YSgBSA"
            },
            {
                text: "7. ¿Hay algo que te detenga al escribir? ¿Silencios que (aún) no has logrado romper?",
                video: "https://www.youtube.com/embed/el_lCtGGQXE"
            },
            {
                text: "8. ¿Cómo inscribirías tu obra en la poesía ecuatoriana?",
                video: "https://www.youtube.com/embed/doZV2G8dMwY"
            }
        ],
        questions: [
            "Realiza, utilizando aplicaciones de internet, una nube de palabras con los principales conceptos que comprendiste del estudio introductorio escrito por Abril Altamirano.",
            "Construye un avatar de la autora, utilizando herramientas digitales, donde el avatar comente sus principales creencias a propósito de la literatura. Las puedes obtener de mirar su entrevista a profundo. ",
            "Selecciona, del libro de Yuliana Ortiz Ruano, 10 términos que no te sean conocidos. Después realiza un glosario con los mismos, investigando su significado en internet.",
            "Utilizando cualquier tipo de herramienta digital que te permita realizar infografías, realiza una que sintetice lo dicho por la autora en su libro.",
            "Selecciona un poema de la autora y marca, con diferentes colores, cuáles son las ideas principales de la misma y cuáles las secundarias. ",
            "Realiza un video de ti mismo (no más de 40 segundos), explicando a tus compañeros, por qué recomiendas leer a Yuliana Ortiz Ruano.",
            "Graba un audio tuyo, leyendo el poema de Yuliana Ortiz Ruano que más te haya gustado.  ",
            "Finalmente, escribe un ensayo en el que indiques cuáles son los elementos que te gustaron de la lectura de Yuliana Ortiz Ruano y cuáles no."
        ]
    },
    {
        id: "6",
        name: "Edwin Alcarás",
        bookName: "Cuentos",
        orderName: 1,
        orderBook: 1,
        isUDLACoedition: false,
        bookLink: "https://online.anyflip.com/ttorr/bzrn/index.html",
        bookGenre: 2,
        bio: "<p><strong>Edwin Alcarás</strong><br/>Quito, 1981.<br/>Escritor, editor y profesor universitario.<br/><br/>Periodista cultural por casi una década, abandonó el diarismo para dedicarse a leer y escribir. Su primer libro de cuentos, <i>La tierra prometida</i>, fue galardonado, en 2010, en el Primer Concurso Nacional de Literatura del Gobierno de Pichincha. En 2017 se publicó su relato <i>Orfanato</i>, que también ganó un fondo de apoyo del Ministerio de Cultura y Patrimonio del Ecuador. Su primera novela, <i>Mal de espejos</i>, ha sido terminada durante la pandemia de 2020. <br/><br/>En ensayo ha publicado <i>Ironía y novela</i>, una lectura desde György Luckács sobre una novela de Roberto Bolaño (Editorial Académica Española, 2018); y <i>Verdad y barroco</i>, una interpretación filosófica sobre una obra de Carlos Monsiváis (PUCE, 2018). <br/><br/>Tiene sendos estudios de posgrado en Filosofía y Pensamiento social (Quito, 2019), Literatura Hispanoamericana (Quito, 2016), y Filología Hispánica (Madrid, 2013). Actualmente es profesor de la Pontificia Universidad Católica del Ecuador, y cursa estudios de doctorado en Lingüística y Filosofía en la Universidad de Perpignan Via Domitia, Francia.<br/><br/>Como editor, lleva delante la colección de filosofía “Cátedra abierta. Problemas de filosofía ecuatoriana” con el Centro de Publicaciones de la Universidad Católica del Ecuador.</p>",
        imgPictureHex: "picture6.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH6.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "interview6.png",
        imgBookHex: "book6.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: false,
        criticalStudyPdf: "",
        hasInterview: true,
        hasCriticalStudy: true,
        interviews: [
            {
                text: "1. En tus cuentos está tematizada constantemente la escritura literaria como una pulsión emocional vital de los personajes. ¿Cómo ha sido tu relación personal con la escritura literaria? ",
                video: "https://www.youtube.com/embed/cp8USKjiOH0"
            },
            {
                text: "2. Tus personajes a menudo experimentan arrebatos o situaciones límite que los ponen contra las cuerdas, por decirlo de algún modo. ¿De dónde proviene este interés tuyo por las situaciones límite, los pasajes más existencialmente dramáticos?",
                video: "https://www.youtube.com/embed/7rpF0yxrHfk"
            },
            {
                text: "3. Se ha observado que tu prosa se desenvuelve en ámbitos de oscuridad, semi-velados, en donde las tramas se desarrollan entre los secretos y las sombras. ¿Cómo se da tu creación de las historias: las conoces por completo desde un inicio, se te presentan también con un halo de misterio…?",
                video: "https://www.youtube.com/embed/uOOFmWDn2GY"
            },
            {
                text: "4. Tu primer libro de ficción se titula <i>La tierra prometida</i>: ¿a qué buscabas aludir con ese concepto y referencia bíblica?",
                video: "https://www.youtube.com/embed/zyIc0Wgc0oY"
            },
            {
                text: "5. En tu proceso de escritura narrativa, ¿a qué elemento sueles dar mayor importancia (personajes, trama, ambiente, tono de la prosa…)? ¿Y cuál de ellos te presenta especial dificultad?",
                video: "https://www.youtube.com/embed/6D3AjsZR3Uc"
            },
            {
                text: "6. ¿Cómo crees que ha alimentado a tu escritura de ficción tu experiencia y recorrido como periodista cultural y cronista?",
                video: "https://www.youtube.com/embed/OCS8z_uP19o"
            },
            {
                text: "7. ¿Y qué relación puedes tender entre tu trabajo académico (tu estudio minucioso de Bolívar Echeverría, de Monsiváis o del barroco, por ejemplo) con tus inquietudes literarias?",
                video: "https://www.youtube.com/embed/dykJCmtllg8"
            },
            {
                text: "8. ¿Cómo ha cambiado en el tiempo (si lo ha hecho) tu relación con la escritura literaria?",
                video: "https://www.youtube.com/embed/VVj8aG1NnyA"
            },
            {
                text: "9. ¿Cuáles serían para ti las características esenciales de un cuento, de acuerdo con tu gusto personal?",
                video: "https://www.youtube.com/embed/DrgJcB4lV84"
            },
            {
                text: "10. ¿Cómo observas a la generación contemporánea (a la que perteneces) de escritores y escritoras del Ecuador?",
                video: "https://www.youtube.com/embed/q-L3suVjE40"
            }
        ],
        questions: [
            "Realiza, utilizando aplicaciones de internet, una nube de palabras con los principales conceptos que comprendiste del estudio introductorio.",
            "Construye un avatar del autor, utilizando herramientas digitales, donde el avatar comente sus principales creencias a propósito de la literatura. Las puedes obtener de mirar su entrevista a profundo. ",
            "Selecciona, del libro de Edwin Alcarás, 10 términos que no te sean conocidos. Después realiza un glosario con los mismos, investigando su significado en internet.",
            "Utilizando cualquier tipo de herramienta digital que te permita realizar infografías, realiza una que sintetice lo dicho por un narrador seleccionado de alguno de sus cuentos.",
            "Selecciona un cuento del autor y grafica la atmósfera o espacio en el que se desarrolla la historia. ",
            "Realiza un video de ti mismo (no más de 40 segundos), explicando a tus compañeros, por qué recomiendas leer a Edwin Alcarás.",
            "Graba un audio tuyo, leyendo una parte de un cuento de Edwin Alcarás; puede ser el que más te haya gustado.  ",
            "Finalmente, cambiar el final de una de las historias y explicar sus razones para su decisión.  "
        ]
    },
    {
        id: "7",
        name: "Salvador Izquierdo",
        bookName: "Cuentos",
        orderName: 4,
        orderBook: 4,
        bookLink: "https://online.anyflip.com/ttorr/ilfr/index.html",
        bookGenre: 2,
        isUDLACoedition: true,
        bio: "<p><strong>Jorge Izquierdo Salvador</strong><br/><br/>Es decano de Formación General de la Universidad De Las Américas. Cuenta con un PhD en letras por la University of British Columbia y una maestría en Estudios Hispanoamericanos por la University of Washington.<br/><br/>Bajo el pseudónimo Salvador Izquierdo ha publicado las novelas <i>Una Comunidad Abstracta</i> (Cadáver Exquisito, 2015; Beatriz Viterbo, 2021), <i>Te Faruru</i> (Campaña Nacional de Lectura, 2016) y <i>El Nuevo Zaldumbide</i> (Festina Lente, 2019), ganadora del Premio Joaquín Gallegos Lara. Tiene una columna mensual en la <i>Revista Mundo Diners</i>. Su trabajo incluye guiones de cine y edición.</p>",
        imgPictureHex: "picture7.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH7.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "",
        imgBookHex: "book7.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: true,
        criticalStudyPdf: "https://online.anyflip.com/ttorr/tbue/index.html",
        hasInterview: false,
        hasCriticalStudy: true,
        interviews: [],
        questions: [
            "Imagina una entrevista con Salvador Izquierdo sobre diversos temas presentes en sus cuentos (como la muerte, la paternidad, la literatura, los viajes, etc.) y redacta las preguntas de modo que las respuestas sean frases literales extraídas de su libro.",
            "Selecciona un cuento del autor y grafica la atmósfera o espacio en el que se desarrolla la historia. Luego, expón al grupo los distintos elementos de tu representación gráfica.",
            "Del cuento «Tomás Gutiérrez Alea», escoge veinte de las preguntas retóricas y en grupos intenta responderlas literalmente, tratando de que el texto resultante tenga un sentido claro.",
            "Reescribe el cuento «Harold» desde la perspectiva del personaje llamado justamente Harold.",
            "Conversen qué sintieron y pensaron con respecto al lenguaje desplegado en el cuento «Obseno». A partir de ello, reflexionen sobre la complejidad del papel de la ortografía en la escritura literaria; pueden recurrir a alguna de las ideas del estudio crítico de Fabrizio C. Rivas.",
            "Reflexiona sobre cuál de los cuentos de Salvador Izquierdo se podría adaptar mejor al cine; puedes imaginar detalles como quiénes serían los actores de distintos personajes o qué canciones incluiría la banda sonora. Luego, compartan con el grupo sus argumentos, refiriéndose siempre a los textos literarios.",
            "Utilizando alguna aplicación digital, realiza un mapa conceptual con las nociones principales del estudio escrito por Fabrizio C. Rivas.",
            "Realiza un video, de un minuto de duración, en donde expongas las razones por las que recomendarías la lectura de Salvador Izquierdo, refiriéndote a cuentos puntuales."
        ]
    },
    {
        id: "8",
        name: "Liset Lantigua",
        bookName: "El mar por dentro",
        orderName: 5,
        orderBook: 5,
        bookLink: "https://online.anyflip.com/ttorr/nhjg/index.html",
        bookGenre: 1,
        isUDLACoedition: true,
        bio: "<p><strong>Liset Lantigua</strong><br/><br/>Bibliotecaria, editora, poeta y narradora cubano-ecuatoriana. Su obra ha recibido reconocimientos como Lista de Honor IBBY 2009 por su novela <i>Y si viene la guerra</i> (Grupo Editorial Norma, 2006; LuaBooks, 2018), y el Premio Nacional de Novela Darío Guevara Mayorga por <i>Contigo en la luna</i> (Grupo Editorial Norma, 2009) y por <i>Me llamo Trece</i> (Alfaguara, 2013). Entre otros libros suyos están los poemarios <i>Bajo el célico gris</i> (CCE, 2002), <i>Mi amada Istar</i> (CCE, 2004), <i>Como un navío en paz</i> (Manthra, 2006); <i>Quiero ese beso</i> (Zonacuario, 2014), <i>Ahora que somos invisibles</i> (Santillana, 2014); <i>Sofi, tu mirada</i> (Edinun, 2015), <i>Todo el amor que recuerdo</i> (CCE, 2017) y <i>Ancla</i> (Amargord, 2017). En 2018, Planeta Lector, de Colombia, publicó su novela lírica <i>Los trenes se demoran</i>. Su obra más reciente es <i>Todo a punto de estallar</i> (en Puerto Betún) (Loqueleo, Santillana, 2023). Es máster en Edición por la Universidad Autónoma de Barcelona. Coordinó la Red Metropolitana de Bibliotecas de Quito y actualmente dirige la biblioteca de la Universidad de Las Américas (UDLA - Ecuador).  </p>",
        imgPictureHex: "picture8.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH8.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "",
        imgBookHex: "book8.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: true,
        criticalStudyPdf: "https://online.anyflip.com/ttorr/rjnh/index.html",
        hasInterview: false,
        hasCriticalStudy: true,
        interviews: [],
        questions: [
            "Utilizando cualquier tipo de herramienta digital de diseño, elaboren en grupos portadas alternativas para el libro <i>El mar por dentro</i>; y expliquen los elementos de su propuesta en relación con los poemas.",
            "Selecciona, del libro de Liset Lantigua, 10 términos que no te sean conocidos. Después realiza un glosario con ellos, investigando su significado en diferentes diccionarios. Reflexionen en grupo las distintas acepciones y comenten cuáles van mejor en el contexto de los poemas.",
            "En grupos, realicen un mapa conceptual con las nociones principales del estudio crítico de Mildred Nájera.",
            "Investiga sobre algún otro escritor o escritora que se haya radicado permanentemente fuera de su país de origen. A raíz de lo que encuentres, infiere algunas de las constantes que condicionan la creación literaria atravesada por la migración.",
            "En varios poemas de Liset Lantigua se despliegan imágenes del mar. Escoge al menos tres de ellas para representarlas gráficamente (puedes usar herramientas tecnológicas), considerando la circunstancia afectiva comunicada de cada texto puntual.",
            "Imagina que debes escribir sobre la geografía de tu lugar de residencia: ¿qué elementos incluirías, y de qué modos los caracterizarías? Compartan en grupo sus perspectivas personales.",
            "Escribe un poema personal en el que explores las sensaciones que tienes cuándo miras la luna, miras el mar o una montaña (escoge alguno de estos elementos para la escritura de tu poema). ",
            "Escoge el poema que más te haya gustado del libro. Después, has un audio leyendo el poema. Finalmente, envíaselo a una persona que sea especial para ti, explicando qué aspectos te impactaron especialmente del texto.",
        ]
    },
    {
        id: "9",
        name: "Eduardo Varas Carvajal",
        bookName: "Shows",
        orderName: 13,
        orderBook: 13,
        bookLink: "https://online.anyflip.com/ttorr/eqjg/index.html",
        bookGenre: 2,
        isUDLACoedition: true,
        bio: "<p><strong>Eduardo Varas Carvajal</strong><br/>(Guayaquil, 1979). <br/><br/>Escritor, músico y periodista. En 2011 fue seleccionado por la FIL de Guadalajara como uno de los «25 secretos mejor guardados de América Latina». Ha publicado <i>Conjeturas para una tarde</i> (BCE, 2007), <i>Los descosidos</i> (Alfaguara, 2010), <i>Faltas ortográficas</i> (CCE, 2017), <i>Esas criaturas</i> (Cadáver Exquisito, 2021) y <i>Las tres versiones</i> (Cadáver Exquisito, 2022). Con este último libro ganó el premio de novela corta Miguel Donoso Pareja, de la Feria Internacional del Libro de Guayaquil. Actualmente es editor adjunto del medio digital GK y docente en la facultad de Comunicación y Artes Visuales, de la Universidad de Las Américas. <p/>",
        imgPictureHex: "picture9.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH9.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "",
        imgBookHex: "book9.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: true,
        criticalStudyPdf: "https://online.anyflip.com/ttorr/pzlo/index.html",
        hasInterview: false,
        hasCriticalStudy: true,
        interviews: [],
        questions: [
            "Observen la imagen de portada del libro digital <i>Shows</i> y enlisten qué temáticas les sugiere el collage, explicando al grupo sus deducciones.",
            "Investiga en internet (y en otras fuentes) sobre los libros del escritor ecuatoriano Eduardo Varas, e identifica (detallando tus argumentos) cómo los cuentos de <i>Shows</i> se insertan en su trayectoria.",
            "En grupos, cambien el final de una de las historias del libro (pero respetando en la medida de lo posible la coherencia con el cuento original) y expliquen las razones de su decisión. ",
            "A partir de la lectura del cuento «Prólogo», conversen en grupo sobre las particularidades de la relación vida-obra. Pueden incorporar ejemplos que investiguen sobre artistas (no solo escritores) que hayan tenido una existencia fuera de lo común, y cómo eso ha repercutido en sus creaciones.",
            "Imagina el encuentro de dos o más personajes de Eduardo Varas que provengan de distintos cuentos del libro. Luego expón al grupo cómo crees que se desarrollaría dicho evento.",
            "Selecciona un cuento del libro y grafica el espacio en el que se desarrolla la historia, poniendo énfasis en las sensaciones y emociones que te ha despertado la lectura.",
            "En grupo, conversen sobre el concepto de «literatura intrusa» que Sandra Araya propone en el estudio crítico sobre <i>Shows</i>. Luego, intenten imaginar cómo se aplicaría tal idea a otras expresiones artísticas (como por ejemplo una «música intrusa» o una «pintura intrusa») y expongan en plenaria sus conclusiones.",
            "Escoge de tres a cinco canciones (de cualquier género musical) que podrían constituir la banda sonora del libro <i>Shows</i>; luego comparte con el grupo tus elecciones, explicando el porqué de cada una."
        ]
    },
    {
        id: "10",
        name: "Ángeles Martínez Donoso",
        bookName: "Error de raccord",
        orderName: 6,
        orderBook: 6,
        bookLink: "https://online.anyflip.com/ttorr/diwa/index.html",
        bookGenre: 1,
        isUDLACoedition: false,
        bio: "<p><strong>Ángeles Martínez Donoso</strong><br/>Cuenca de los Andes, 1980 <br/><br/>Ha representado al país con su poesía en eventos nacionales e internacionales como «Poetas a través de las Américas» (Trinity University, San Antonio, Texas); la Feria del Libro de Santiago de Chile y la de La Habana, Cuba, entre otros. Su poesía aparece en más de cincuenta importantes antologías en Ecuador, Colombia, Chile, México, España, Francia, etc.<br/><i>Error de raccord</i> se suma a otros nueve libros de poesía de su autoría: <i>Entrecortada </i>(La Caída, Cuenca, 2021); <i>Cuatro cuartos y Delirio de luto</i>, junto al poeta mexicano Víctor Cabrera (El Ángel Editor, Quito, 2014); <i>Trasnoche</i> (Casa de la Cultura Núcleo del Azuay, Cuenca, 2012); <i>Trozos de Vidrio</i> (Casa de la Cultura Ecuatoriana, Quito, 2007); <i>Subcielo</i> (H-onda de David, Casa de la Cultura Núcleo del Azuay-Universidad de Cuenca, 2004); <i>Neos. Plaquette</i> (Cuenca, 2000); <i>Un Lapso de Impiedad</i> (Universidad de Cuenca,1999). En colectivo: <i>Aunque Bailemos con la Más Fea</i> (Editorial Ziete, 2002); <i>Nadie nos Quita lo Bailado</i> (Editorial Ziete, 2005).         </p>",
        imgPictureHex: "picture10.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH10.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "",
        imgBookHex: "book10.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: true,
        criticalStudyPdf: "https://online.anyflip.com/ttorr/rtxs/index.html",
        hasInterview: false,
        hasCriticalStudy: true,
        interviews: [],
        questions: [
            "Investiga qué se entiende por «raccord» en el lenguaje cinematográfico, y reflexiona cómo se relaciona el concepto de «error de raccord» con la fotografía de portada del libro de Ángeles Martínez Donoso.",
            "Utilizando alguna aplicación digital, realiza un mapa conceptual con las nociones principales del estudio escrito por Frances Siman.",
            "A la manera en que Ángeles Martínez Donoso elabora los poemas de su libro, como un diálogo con alguna obra de otro campo artístico, escribe un pequeño poema o una narración breve inspirándote en algún o alguna artista de tu elección.",
            "Escoge tres de los creadores con los que dialogan los poemas de <i>Error de raccord</i>, investiga sobre su trabajo, y relaciona las principales características de sus obras con los poemas correspondientes.",
            "Varios de los textos de Á. Martínez Donoso se dirigen a un «tú». Imagina que eres la persona a la que interpelan esos poemas y escribe un texto (en verso o prosa) de posible contestación. ",
            "Escoge tres poemas de <i>Error de raccord</i> e imagina qué anécdotas pudieron haberlos inspirado. Luego, redacta esas breves narraciones.",
            "En grupos, investiguen sobre otras obras de la poeta Ángeles Martínez, y analicen cómo estos poemas forman parte de su propuesta estética. ",
            "Escoge de tres a cinco canciones (de cualquier género musical) que podrían constituir una banda sonora de <i>Error de raccord</i>; luego comparte con el grupo tus elecciones, explicando el porqué de cada una. "
        ]
    },
    {
        id: "11",
        name: "Diego Chamorro",
        bookName: "¡Los héroes odian las palabras!",
        orderName: 3,
        orderBook: 3,
        bookLink: "https://online.anyflip.com/ttorr/blqx/index.html",
        bookGenre: 3,
        isUDLACoedition: false,
        bio: "<p><strong>Diego Chamorro</strong><br/>Quito, 1984<br/><br/>Director Académico de la Escuela de Pensamiento Crítico «Las babas del diablo». Librero en Librería Cosmonauta. Egresado de la Maestría de Investigación en Literatura con mención en Literatura Latinoamericana de la Universidad Andina Simón Bolívar (UASB). Magíster en Estudios Literarios por la Universidad de Buenos Aires (UBA). Egresado del Posgrado de Filosofía «Programa de actualización de Problemas Filosó cos Contemporáneos» de la UBA. Licenciado en Comunicación con mención en Comunicación y Literatura por la Pontificia Universidad Católica del Ecuador (PUCE). Editor general del Centro de Publicaciones de la Casa de la Cultura Ecuatoriana. Docente de Filosofía, Historia de la Civilizaciones, Redacción Académica, Lenguaje y Comunicación, y Seminario de titulación en la UDLA. Ayudante de cátedra en la Maestría de Literatura Hispanoamericana y Ecuatoriana de la PUCE en las materias de Semiótica del Texto Narrativo y Teoría Literaria II. Adjunto a la cátedra de Teoría y Crítica C de la Facultad de Filosofía y Letras de la UBA. Investigador de la UASB y gestor del Observatorio de Crítica Literaria Ecuatoriana. <p/>",
        imgPictureHex: "picture11.png",
        imgPictureHexRef: "©Foto de Florencia Luna",
        imgGenre: "pictureH11.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "",
        imgBookHex: "book11.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: true,
        criticalStudyPdf: "https://online.anyflip.com/ttorr/znxj/index.html",
        hasInterview: false,
        hasCriticalStudy: true,
        interviews: [],
        questions: [
            "Investiga cómo se ha definido el género literario del ensayo, y analiza si en el libro <i>¡Los héroes odian las palabras!</i> se ven reflejadas tales definiciones. Argumenta tu respuesta.",
            "Realiza una nube de palabras que defina lo que es el ensayo, desde tu propia comprensión y a partir del libro de Diego Chamorro. ",
            "En el libro <i>¡Los héroes odian las palabras!</i> se habla sobre varios autores ecuatorianos como Sandra Araya, Gabriela Ponce o Natasha Salguero. Investiga cuál es la obra de estas autoras y realiza un cuadro de resumen con los hallazgos. ",
            "Utilizando alguna aplicación digital, realiza un mapa conceptual con las nociones principales del estudio escrito por Alicia Ortega. A partir de ello, propongan títulos alternativos para el libro.",
            "En su libro, Diego Chamorro dedica varias páginas a autores como Borges, Derrida, Barthes, entre otros. Escoge uno de ellos e investiga sobre su obra; luego, relaciona lo que encuentres con las ideas presentes en los ensayos de Chamorro.",
            "En grupos, investiguen sobre el género de «crítica literaria», y elaboren una exposición sobre sus hallazgos. Tomen en cuenta lo que dice Diego Chamorro en su libro al respecto. Luego, debatan la importancia actual de la crítica.",
            "En la sección «Una vuelta a los espacios», el autor reflexiona sobre cómo habitar distintos lugares. Con tu celular, toma fotos de espacios significativos que habitas. Después, escribe un párrafo de descripción de cada uno. (Elige como máximo cinco). ",
            "Escribe un ensayo, de una página máximo, que tenga como motivo central la vida y los espacios que se habitan. Experimenta, dentro del ensayo, imaginando tu vida en otra ciudad. ¿Cómo sería?  "
        ]
    },
    {
        id: "12",
        name: "Juan Romero Vinueza",
        bookName: "Breves escenas de irrealismo suburbano",
        orderName: 11,
        orderBook: 11,
        bookLink: "https://online.anyflip.com/ttorr/anoj/index.html",
        bookGenre: 1,
        isUDLACoedition: false,
        bio: "<p><strong>Juan Romero Vinueza</strong><br/>Quito, Ecuador, 1994 <br/><br/>B.A. en Literatura en la PUCE (Ecuador). M.A. en Literatura Hispanoamericana en la Universidad de Guanajuato (México). Fue co-editor de <i>Cráneo de Pangea</i>. Ha colaborado con revistas de Venezuela, Chile, Perú, España, Estados Unidos, México, Reino Unido y Ecuador. Ha publicado en poesía: <i> Revólver Escorpión </i> (La Caída, 2016), <i>39 poemas <s>de mierda</s> para mi primera esposa </i>(Turbina, 2018; Ed. Liliputienses, 2020; Mantra, 2020), <i>Dämmerung [o cómo reinventar a los ídolos]</i> (Ed. Liliputienses, 2019; La Caída, 2021), Mención de Honor del Premio Nacional de Poesía Jorge Carrera Andrade 2019; y <i>lírica fracturada para traductores tristes </i> (Municipio de Cuenca, 2021), obra ganadora de la Convocatoria Editorial 2021 del GAD Cuenca en la categoría de Poesía. La primera antología de su obra es <i>Ínfimo territorio kamikaze</i> (Municipalidad de Lima, 2021). Compiló, con Abril Altamirano, <i>Despertar de la hydra: antología del nuevo cuento ecuatoriano</i> (La Caída, 2017), obra ganadora de los Fondos Concursables 2016-2017 del Ministerio de Cultura y Patrimonio del Ecuador. Compiló y tradujo, con Kimrey Anna Batts, <i>País Cassava / Casabe Lands</i> (La Caída, 2017). Fue uno de los ganadores del Certamen de Ensayo Luis Alberto Arellano y su texto forma parte de <i> Erradumbre </i>(Mantis, 2021). <i>Breves escenas de irrealismo suburbano </i>(La Caracola, 2023) es su quinto libro de poesía.<p/>",
        imgPictureHex: "picture12.png",
        imgPictureHexRef: "©Foto de Kimrey Anna Batts",
        imgGenre: "pictureH12.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "",
        imgBookHex: "book12.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: true,
        criticalStudyPdf: "https://online.anyflip.com/ttorr/vgcd/index.html",
        hasInterview: false,
        hasCriticalStudy: true,
        interviews: [],
        questions: [
            "Selecciona, del libro de Juan Romero Vinueza, diez términos que no te sean conocidos. Después realiza un glosario con ellos, investigando su significado en diferentes diccionarios. Reflexionen en grupo las distintas acepciones y comenten cuáles van mejor en el contexto de los poemas.",
            "Realiza, utilizando aplicaciones de internet, una nube de palabras con los principales conceptos que comprendiste del estudio crítico sobre <i>Breves escenas de irrealismo suburbano</i> escrito por Abril Altamirano.",
            "Utilizando cualquier herramienta digital de diseño, elaboren en grupos portadas alternativas para el libro <i>Breves escenas de irrealismo suburbano</i>; y expliquen los elementos de su propuesta en relación con los poemas.",
            "En grupo, investiguen sobre las distintas corrientes literarias que se han denominado «realismo»; discutan sobre cómo podrían conceptualizar un tipo de realismo en la actualidad, y luego describan en qué consistiría el «irrealismo» que propone Juan Romero Vinueza en este libro.",
            "Escoge tres poemas de <i>Breves escenas de irrealismo suburbano</i> y luego, a partir de la sensación que te dejó su lectura, escribe tus propios poemas manteniendo únicamente el título de cada texto de Romero Vinueza.",
            "Busca textos poéticos de otros autores y autoras (de cualquier época y origen) que te permitan relacionarlos, mediante aspectos puntuales, con los poemas de Juan Romero Vinueza; expón al resto del grupo el resultado de lo que has encontrado.",
            "Graba un audio en el que leas un poema de Juan Romero Vinueza; puede ser el que más te haya gustado. Analicen en grupo qué palabras o versos se destacan en la expresión oral, y comenten qué sentidos pueden tener dichos énfasis.",
            "Realiza un video de ti mismo, de un minuto de duración, explicando por qué recomendarías leer a Romero Vinueza."
        ]
    },
    {
        id: "13",
        name: "Pablo José Cevallos",
        bookName: "Bosques que arden maravillosamente",
        orderName: 2,
        orderBook: 2,
        bookLink: "https://online.anyflip.com/ttorr/hspm/index.html",
        bookGenre: 2,
        isUDLACoedition: false,
        bio: "<p><strong>Pablo José Cevallos</strong><br/>Portoviejo, 1981<br/><br/>Creció en Guayaquil, donde vivió por 28 años. Se mudó a Quito en 2016. Abogado. Su educación en literatura se dio en talleres con grandes escritores ecuatorianos, casi todos más jóvenes que él. Este es su primer libro.<p/>",
        imgPictureHex: "picture13.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH13.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "",
        imgBookHex: "book13.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: true,
        criticalStudyPdf: "https://online.anyflip.com/ttorr/oige/index.html",
        hasInterview: false,
        hasCriticalStudy: true,
        interviews: [],
        questions: [
            "En grupos, reflexionen qué les hace sentir y pensar el título <i>Bosques que arden maravillosamente</i>; y enlisten las temáticas que les sugiere tal imagen.",
            "En el cuento «Un zapato», el narrador se dirige a su pareja. Imagina la misma conversación relatada desde la perspectiva de ella, y escribe el resultado.",
            "En grupos, relean atentamente el cuento que da nombre al libro, fijándose en el personaje de la narradora protagonista. Luego, analicen su verosimilitud (cuán creíble les resulta) y escojan diez pasajes del texto que sustenten su análisis.",
            "A la manera del cuento «La Reina de Jamaica», imagina una entrevista con algún músico o música que te guste; redacta el diálogo inventando sus respuestas.",
            "Utilizando aplicaciones de internet, realiza una infografía con los principales conceptos que comprendiste del estudio introductorio escrito por Andrés Cadena.",
            "En grupo, a partir de uno de cuentos del libro imaginen cómo pudiera proseguir la historia. Escríbanla y expliquen al resto las razones de su decisión.",
            "Piensa en otros autores o autoras (de cualquier origen y época) que conozcas y que tengan alguna similitud con la escritura de Pablo José Cevallos. Expón al grupo tal relación, argumentando con ejemplos de los textos.",
            "Escoge de tres a cinco canciones (de cualquier género musical) que podrían constituir la banda sonora de <i>Bosques que arden maravillosamente</i>; luego comparte con el grupo tus elecciones, explicando el porqué de cada una."
        ]
    },
    {
        id: "14",
        name: "Silvia Stornaiolo",
        bookName: "Creo que mi madre está enamorada de mí",
        orderName: 12,
        orderBook: 12,
        bookLink: "https://online.anyflip.com/ttorr/pjbp/index.html",
        bookGenre: 2,
        isUDLACoedition: false,
        bio: "<p><strong>Silvia Stornaiolo</strong><br/>Quito, 1980<br/><br/>Ha realizado estudios de literatura, psicología e historia del arte. Con su primer libro de cuentos, <i>Cuerva Críos</i>, ganó el premio de narrativa concedido por el Consejo Provincial de Pichincha en 2010, el cual está poblado de relatos audaces que hablan sobre la cotidianidad de una mujer honesta, sufrida y rebelde.En 2012 publicó su novela <i>Tanta Joroba</i>, que ilustra la deformidad conyugal y su decadencia, desenmascarando la desesperación de llevar a una relación matrimonial. 2014 es el año en que lanza su segunda novela, <i>Tenga</i>, que resume en varios capítulos relatos de una familia conflictiva. <i>Fundamental</i> es su segunda entrega de cuentos, con la Casa de la Cultura en el año 2016, donde narra aventuras de desamor y romances absurdos. En 2019 presenta su libro <i>Facilona</i>, una reunión de cuentos irreverentes, cargados de humor y sensaciones. Ha participado en Ferias de Libro nacionales e internacionales, y ha recibido menciones y galardones por su trabajo literario.<p/>",
        imgPictureHex: "picture14.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH14.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "",
        imgBookHex: "book14.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: true,
        criticalStudyPdf: "https://online.anyflip.com/ttorr/uzdo/index.html",
        hasInterview: false,
        hasCriticalStudy: true,
        interviews: [],
        questions: [
            "Observa detenidamente la ilustración de portada del libro <i>Creo que mi madre está enamorada de mí</i>. Describe los elementos presentes con el mayor detalle posible y en grupo reflexionen qué temáticas les sugieren con respecto a los cuentos.",
            "Selecciona uno de los relatos del libro y analiza al/a la protagonista. Después, construye un avatar de dicho personaje utilizando herramientas digitales, en el que queden patentes sus principales características.",
            "Utilizando aplicaciones digitales, realiza una infografía con los principales conceptos que comprendiste del estudio introductorio escrito por Yanko Molina.",
            "Imagina que la voz narrativa de uno de los cuentos en lugar de ser un personaje femenino sería masculino; escribe cómo quedaría la historia.",
            "Realiza un video, de un minuto de duración, en donde expongas cuáles son las características de la escritura de Silvia Stornaiolo que más te agradaron y las que menos; argumenta por qué.",
            "En grupos, escojan uno de los cuentos de <i>Creo que mi madre está enamorada de mí</i> para adaptarlo a una versión radiofónica, y escriban cómo sería el guion.",
            "Conversen en el grupo sobre otras narradoras ecuatorianas (de cualquier época) que conozcan (si no conocen ninguna, investiguen un poco). Establezcan una lista de semejanzas y diferencias con la escritura de Silvia Stornaiolo.",
            "Escoge de tres a cinco canciones (de cualquier género musical) que podrían constituir la banda sonora de <i>Creo que mi madre está enamorada de mí</i>; luego comparte con el grupo tus elecciones, explicando el porqué de cada una."
        ]
    },
    {
        id: "15",
        name: "Santiago Vizcaíno Armijos",
        bookName: "------",
        orderName: 15,
        orderBook: 15,
        bookLink: "https://online.anyflip.com/ttorr/rdcc/index.html",
        bookGenre: 3,
        isUDLACoedition: false,
        bio: "<p><strong>Santiago Vizcaíno Armijos</strong><br/>Quito, 1982 <br/><br/>Licenciado en Comunicación y Literatura por la Pontificia Universidad Católica del Ecuador. Becario de Fundación Carolina en la Universidad de Málaga, donde cursó un máster en Gestión del Patrimonio Literario. Fue director del Centro de Publicaciones de la PUCE y de su Feria Internacional del Libro. Su primer libro de poesía, <i>Devastación en la tarde</i>, recibió el Premio Nacional de Literatura del Ministerio de Cultura, en 2008. Su  ensayo <i>Decir el silencio, en torno a la poesía de Alejandra Pizarnik</i> obtuvo el segundo lugar en esa categoría. Recibió el segundo Premio Pichincha de Poesía 2010 por <i>En la penumbra</i>. En 2015 apareció su libro de poesía: <i>Hábitat del camaleón</i> (Ruido Blanco) y una <i>plaquete</i> de su poema «Canción para el hijo» (Hanan Harawi). Ha publicado también un libro de cuentos: <i>Matar a mamá</i> (La Caída, 2012, 2015), una novela: <i>Complejo</i> (La Caída, 2017), y el libro de ensayo <i>«Casa Tomada». Reinvención de un mito, recogimiento de un espíritu</i> (La Caracola, 2018). En 2018 fue ganador de la convocatoria del Sistema Nacional de Fondos Concursables por su libro <i>Taco bajo</i> (La Caída, 2019). En 2020 y 2021 fue curador de la Feria Internacional del Libro de Quito. Y en 2021 publicó el libro de cuentos <i>El ángel de la peste</i> (Educar-Plan Lector Colombia; La Caída, Grado Cero, Ecuador; Universidad Iberoamericana, México). En 2022 fue Jurado del Premio Casa de las Américas y publicó el libro de poesía <i>El viento a contrapelo de mi sombra</i> (Manofalsa, Perú), ganador de la convocatoria del IFCI 2022.<p/>",
        imgPictureHex: "picture15.png",
        imgPictureHexRef: "",
        imgGenre: "pictureH15.png",
        imgCriticalStudyHex: "criticalStudy.png",
        imgInterviewHex: "",
        imgBookHex: "book15.png",
        imgTeachersHex: "teacherActivity.png",
        isCriticalStudyPdf: true,
        criticalStudyPdf: "",
        hasInterview: false,
        hasCriticalStudy: false,
        interviews: [],
        questions: []
    }
]