export const criticalStudy2 = `
<h2>El poema existe cuando la realidad es débil</h2>
<p class="author-name">Por Juan Romero Vinueza</p>
<p class="text-end">el artista es fuerte si lo real es débil<br/><br/>
(«Afiches en remate I. Claes Oldenburg,<br/> <i>Soft Typewriter</i>, 1963», Juan José Rodinás) </p>
<p>Hablar de la obra de Juan José Rodinás (Ambato, 1979) es detenerse en una de las poéticas más prolíficas y particulares del Ecuador en lo que va del siglo XXI. La estética que ha ido desarrollando —porque aún es una acción en proceso— desde sus primeros textos hasta lo más actuales es una prueba irrefutable de ello. Rodinás no se repite porque su búsqueda es reinventar <i>algo</i> en los nuevos poemas que escribe. Eso sí, es un autor consciente de la tradición que le antecede (¿o, más bien, de las <i>tradiciones</i>?), pero también de su obra anterior. No es extraño encontrarse con reescrituras o refacciones en las nuevas ediciones de sus libros. En otros casos, incluso, entabla diálogos intertextuales con su propia obra, reinterpretándola o parodiándola. Como sucede con otros poetas de obras extensas, tener la posibilidad de acercarse a la escritura gracias a una corta muestra o una antología resulta útil para aquellos lectores que apenas lo descubren. </p>
<p>Al igual que las compilaciones <i>9 grados de turbulencia interior</i> (Mantis, México, 2014) y <i>Los páramos inversos</i> (Gamar, Colombia, 2014)<sup>1</sup>, <i>Amplitud modulada</i>, la presente antología de Rodinás, propone una lectura desde su obra más actual hasta sus primeros poemas. Esta sucinta selección de su trabajo dibuja una guía de ruta regresiva para conocer su poesía. El libro contiene 32 poemas, pertenecientes a toda su obra publicada desde su primer poemario, <i>Los rastros</i> (2006), hasta <i>Un hombre lento</i> (2019), último que vio la luz. Es así que esta antología recoge 13 años de publicaciones, los cuales comprenden 12 libros: los dos ya citados, más <i>Viaje a la mansedumbre</i> (2009), <i>Blues de las esferas (inédito)<sup>2</sup> , Barrido de campo</i> (2010), <i>Código de barras</i> (2011), <i>Cromosoma</i> (2011), <i>Estereozen</i> (2012), <i>Anhedonia</i> (2013), <i> Kurdistán</i> (2017), <i>Cuaderno de Yorkshire</i> (2018) y <i>Yavarí para cantar bajo los cielos del norte </i> (2019). </p>

<p class="subtitle">Rodinás in an <span class="text-decoration-line-through">un</span>real Nutshell</p>
<p>Es casi imposible condensar la poética de Rodinás en tan pocas páginas. No obstante, intentaré sintetizar en la cortedad de estos apuntes algunas nociones que me parecen significativas para acercarse a su producción de una manera más atenta. Su obra funciona como un viaje a través de la irrealidad. ¿Por qué un <i>viaje</i> y por qué <i>a través</i>? Creo, y lo digo al considerarme un asiduo lector de su poesía, que el trabajo de Rodinás tiene un tema predilecto: la (ir)realidad. Si bien este señalamiento podría parecer una bifurcación dicotómica y determinista, llega a convertirse en una onda expansiva de la cual se sirve el poeta para poner en crisis la noción misma de «realismo». </p>
<p>La revisión de la totalidad de sus poemarios publicados da luces acerca de la existencia de una hermandad espiritual con algunos elementos y recursos durante toda su obra. Además de la realidad, es muy frecuente encontrarse referencias a la infancia, la paisajística, la geografía, la movilidad mecánica de los medios de transporte, el poshumanismo, la negación del sujeto, la incertidumbre, la imposibilidad, el tiempo, el lenguaje, la visión, y las reinvenciones de otras artes. Estos elementos son más que temas porque resultan búsquedas estéticas y, sobre todo, obsesiones. </p>
<p>La idea del alejamiento o la superación de la «realidad» (o de la «irrealidad», a secas), es una constante de su escritura. Para plantearla, el poeta echa mano a varios de los temas antes señalados. En el último poema de esta antología —que pertenece a su primer libro publicado, <i>Los rastros</i>—, «Transformer Tranströmer», se leen versos como estos: </p>
<p class="p-all-init-space">Sus manos colmadas de una luz sigilosa,<br/>
traída de un lugar hiperreal, <br/>
convierten al juguete en un ave que lleva<br/>
los racimos del tiempo. </p>
<p>Elijo este fragmento en particular —y no otro— del poema citado porque creo que funciona como un posible punto de partida de su poética al desprender varios de sus intereses/obsesiones. En primera instancia, el «lugar hiperreal», es decir, la geografía (que muchas veces es, más bien, <i>topografía</i>) y la puesta en duda de la realidad. Del mismo modo, la recurrencia a la imagen «irracional» que se encuentra en los límites del lenguaje escrito al proponer una relación pictórica de las palabras: «juguete», «ave», «racimos», «tiempo». Estas dos ideas de lo poético, la puesta en duda de la realidad y la imagen, quizá sean las huellas definitorias del estilo <i>rodinasiano</i>. </p>
<p class="highlight">Es muy frecuente encontrarse referencias a la infancia, la paisajística, la geografía, la movilidad mecánica de los medios de transporte, el poshumanismo, la negación del sujeto, la incertidumbre, la imposibilidad, el tiempo, el lenguaje, la visión, y las reinvenciones de otras artes.</p>
<p>Hago hincapié en ambos puntos porque las ideas —o derivaciones— de la realidad son visibles en casi todos los poemas de la antología. De la misma manera, no hay poema de Rodinás en el que no se presente al menos una imagen irracional próxima a lo ecfrático o, en su defecto, a lo pictórico <i>per se<sup>3</sup></i>. Aclaro: no es que Rodinás sea un poeta ecfrático, en el sentido clásico de la descripción de una pintura o un tapiz. Sucede que su obra, casi siempre, funciona como una écfrasis inversa: la escritura crea la imagen pictórica de un trabajo visual que, muchas veces, no existe más que en la mente del poeta. </p>
<p>Sin embargo, su obra no solamente posee un vínculo con el arte visual, sino que dialoga con otras artes<sup>4</sup>. El autor denominó su poética como «imaginismo ecléctico», en una videoconferencia con el poeta e investigador David G. Barreto (Quito, 1976); es decir, para Rodinás su obra es una estética que reconcilia armónicamente opuestos irreconciliables, lo cual se emparenta con las imágenes irracionalistas mencionadas. El viaje emprendido por la poesía del ecuatoriano se da a través del ojo (la mirada) porque el entendimiento del mundo vive en una tensión entre la visión y la mente. En su poema «Homenaje a Paul Muldoon», perteneciente al libro <i>Barrido de campo</i>, escribe: </p>
<p class="p-all-init-space">El ojo hace un dibujo para la mente.<br/>
Traduce a palabras. <br/>
La mirada es una lengua extranjera. </p>
<p>Estos versos muestran la intención primordial de Rodinás por establecer a la imagen como el mecanismo de funcionamiento de su poesía. Esa tríada de sentidos: imagen-mente-lenguaje codifica todos sus libros, desde el más minimalista (<i>Los rastros</i>) hasta el proyecto más neobarroco (<i>Anhedonia</i>); del más corto (<i>Código de barras</i>) hasta el más largo (<i>Kurdistán</i>); del poemario estructurado enteramente en verso (<i>Viaje a la mansedumbre</i>) hasta el que se formula, casi por completo, en prosa poética (<i>Blues de las esferas</i>); desde los más líricos (<i>Cuaderno de Yorkshire</i> o <i>Yaraví para cantar bajo los cielos del norte</i>) al más experimental (<i>Estereozen</i>). Realizo este señalamiento con algunas de sus publicaciones para mencionar ciertos momentos en su obra, o si se quiere, diferentes etapas. Si bien su obra tiene una <i>firma</i> reconocible, ha pasado por varias mutaciones; los recursos expresivos han sufridos cambios, mas no los intereses y obsesiones propias del autor. </p>
<p class="highlight">Que su obra, casi siempre, funciona como una écfrasis inversa: la escritura crea la imagen pictórica de un trabajo visual que, muchas veces, no existe más que en la mente del poeta.</p>
<p>Precisamente, una de las obsesiones más marcadas de Rodinás es el espacio/tiempo. Me refiero a la fascinación por la geografía, el paisaje, y los medios de transporte mecánicos. Su poesía suele incluir árboles, bosques, y colinas, viento y lluvia, trenes, autos y máquinas; y, por su puesto, hay momentos en los cuales las leyes de la física se rompen porque el poema permite que se rompan. Pienso, por ejemplo, en los versos de «Rapsodia donde el amor incluye una yihad islámica», poema de <i>Kurdistán</i>:</p>
<p class="p-all-init-space">Este paisaje de pérgolas donde paseas bajo un globo aerostático<br/>
y recibes una descarga eléctrica sobre tus mundos derretidos.
<br/><br/>
(Humanos derretidos en una escena derretida). </p>
<p>El poeta ecuatoriano no acepta la idea del paisaje únicamente como algo telúrico (digamos, el <i>beatus ille</i>); para él, las construcciones urbanas como las ciudades o las carreteras son otro tipo de paisaje y geografía. Sin embargo, estas nociones de lo urbano no buscan parecerse al sitio referente o reflejar la realidad, más bien, buscan imaginar realidades alternas que podrían existir en un cuadro vanguardista o, incluso, acercarse a una posible escena del cine de Clase B. Es así como pone sobre la mesa varias visiones, generalmente, absurdas del mundo. Con «absurdo», busco emparentar el trabajo rodinasiano con las imagen irracional o irreal que, eclécticamente, encuentra una armonía en el poema. </p>
<p>Por cuestiones de espacio, solo citaré algunas nociones de <i>mundo</i> percibido en la antología: «La calle de los largos delirios me conduce a la iglesia de la tierra sin mundo» (»Canción de autor. <i>En la Iglesia Evangélica de la Eternidad</i>, 2008», de <i>Barrido de campo</i>); «Hay objetos de plástico: /mundos abandonados sobre un fondo de nubes» («Dollboy filmado por Giorgio de Chirico», de <i>Código de barras</i>); «Un núcleo que se traga el mundo sobre un clavo de paja: útil para hender el cerebro. La realidad se explica como un tanque de sedimentos» («Tachadura: hipersoneto poshumano», de <i>Estereozen</i>). En la poesía de Rodinás habrá mundos siempre que sean pesadillescos, industrializados o irracionalmente bellos, porque darle la vuelta al mundo es también escribir otra realidad.</p>
<p class="highlight">Pone sobre la mesa varias visiones, generalmente, absurdas del mundo. Con «absurdo», busco emparentar el trabajo rodinasiano con las imagen irracional o irreal que, eclécticamente, encuentra una armonía en el poema.</p>
<p class="subtitle">Poeta andino perdido <span class="text-decoration-line-through">(¿o hallado?)</span> en los bosques de Northumbria</p>
<p>El carácter misceláneo de <i>Amplitud modulada</i> se diferencia de la primera antología de su obra, <i>9 grados de turbulencia interior</i>, en tres sentidos: el primero, y más evidente, porque se incluyen libros que aparecieron años después de dicha publicación: <i>Kurdistán, Cuaderno de Yorkshire, Yaraví para cantar bajo los cielos del norte</i> y <i>Un hombre lento</i>; el segundo, dado que el tamaño del libro es más reducido que el anterior (tiene más o menos la mitad de páginas); y el tercero responde a la selección de poemas realizada por Rodinás. Los tres factores confluyen en la particular conformación de esta recopilación de la obra del poeta ecuatoriano porque establecen, justamente, una «amplitud modulada».</p>
<p>Si bien hay varios poemas que se «repiten» en ambas compilaciones, también se dan inclusiones nuevas<sup>5</sup>. En una comparación entre ambas antologías, es notable una predilección diferente por lo que se muestra. En este libro, Rodinás —como antologador de su propia obra— da más espacio a su producción post <i>Estereozen</i>. Este dato es significativo porque hay un giro en la obra rodinasiana después de dicho libro. No solamente por los varios premios<sup>6</sup> obtenidos a partir de esta publicación, si no, más bien, por la preocupación bipartita de dos territorios geográficos específicos que serán trascendentales en su obra siguiente: Ecuador y Reino Unido. </p>
<p>El período post <i>Estereozen</i> coincide con la publicación de los poemarios señalados anteriormente,<sup>7</sup> incluidos en esta nueva antología personal. Sucede lo mismo con el traslado del autor a Leeds, Reino Unido, lugar donde realizó su PhD en Estudios Latinoamericanos en la University of Leeds. Esta pequeña acotación biográfica permite emparentar el cambio de interés temático en sus poemas, enfocado en la ubicación temporal-espacial<sup>8</sup> de las voces líricas. Igualmente, el poeta empezó a firmar sus libros como «Rodinás» a partir de <i>Anhedonia</i> (publicado después de <i>Estereozen</i>), aunque existe una serie de poemas que llevaban esa palabra («Rodinás») en su título en los libros <i>Cromosoma</i> y <i>Estereozen</i>. </p>
<p>Este principal cambio en el interés de las locaciones viene acompañado también la tensión creada en su obra a partir de las montañas los Andes y los bosques de Northumbria. Recalco esto en referencia a los intereses del paisaje (árboles, bosques, montañas, colinas, viento y lluvia) porque ambas geografías comparten, parcialmente, esas características. Hay fragmentos donde el lenguaje yuxtapone ambas latitudes e, incluso, propicia la ruptura de ambas con la realidad. En el poema «Biografía para Freddy Mercury dentro de un cuadro de Caspar David Friedrich / (¿Por qué no existe un punto del espacio-tiempo donde el espacio es tiempo y viceversa?)» de <i>Un hombre lento</i> el poeta dice:</p>
<p class="p-all-init-space"> (Mi realidad, Orfeo Mercury, <br/>
es el infierno lluvioso de una calle quiteña). </p>
<p>La imagen de Orfeo (que <i>encanta</i> con su canto) converge con la figura pop de Freddy Mercury (mítico vocalista de Queen<sup>9</sup> que <i>encantaba</i> con su canto) y es mediadora entre la percepción de la realidad como una calle de capital ecuatoriana en plena lluvia torrencial. La lluvia es una imagen significativa de Quito y de los Andes<sup>10</sup>, no solo en la obra de Rodinás. Se la encuentra en muchos autores que refrieren a esta ciudad o esta región. En otro poema, «Tachadura: hipersoneto poshumano» de <i>Esteroezen</i>, dice: «empezaba a llover hacia atrás, secando un racimo de calles sobre la frente del sol disuelto en las galaxias del campo». La imagen de la calle bajo la lluvia (o a través de la neblina) es recurrente en los pasajes referentes a Ecuador o Reino Unido. La relación se establece por la geografía y el clima de ciertas épocas del año, tanto en el eje urbano como en el rural. </p>
<p>La tensión entre ambas latitudes muestra la noción de extranjería, no solo geográfica sino lingüística. La sensación de <i>out of place</i> no se da únicamente por el desconocimiento del sitio a donde se llega, sino que se evidencia aún más cuando el idioma en el que se vive es distinto; es decir, la codificación mental también cambia. Somos unas personas hablando en español y otras, hablando en inglés. Dicho esto, hay que destacar que Rodinás es un gran lector de la poesía en inglés<sup>11</sup> —quizá uno de los poetas ecuatorianos que mejor conoce esa tradición—. No solo la geografía del país británico es determinante en su obra, sino también la música y la poesía que provienen de esa lengua <sup>12</sup>.</p>
<p>En otros momentos de su poesía, esta inestabilidad mencionada entre los lugares (los Andes y Northumbria) se produce cuando estos se confunden o no existen o están en <i>otro</i> sitio. Es ahí donde ciertos medios de transporte mecánicos replantean el lugar y lo convierten en un <i>no lugar</i>, en un espacio de tránsito. Estos sitios móviles tales como automóviles, motos, buses y, sobre todo, trenes<sup>13</sup> le sirven a Rodinás como excusa para <i>mover</i> la realidad, ponerla en crisis. Estos elementos propician la idea del viaje hacia ninguna parte o hacia un lugar fuera del mundo y de la realidad misma. Pienso en el poema «Antibalada escrita por un <i>nerd</i> en Yorkshire», de <i>Cuaderno de Yorkshire</i>, que inicia diciendo:</p>
<p class="p-all-init-space">¿Hay lugar para mí en el bus que va de Londres<br/>
hacia la realidad? Pase usted, amigo. <br/>
Estoy solo: en mi mente, un hombre juega<br/>
con un balero mientras espera en su silla. <br/>
<br/>
Los pasaportes a la realidad llevan el sello de las lágrimas. <br/>
Luego, quizás de una enfermedad crónica.</p>
<p>No es extraño encontrar versos como estos en la obra de Rodinás. Su apuesta, muchas veces, es vaciar el significado de los lugares para otorgarles otro, irracional, pero aún más simbólico. Transportarse de un sitio a la mente, o de la mente a un sitio, resulta un ejercicio común en su obra<sup>14</sup>. Por ejemplo, en el poema «País punto», de <i>Estereozen</i>, se lee: «Persígueme en este paraje abandonado de paraje, en este vacío de lugar contenido por nadie, en este desierto que anochece sobre la punta de una aguja». Aquí se encuentra otro de los elementos de vital importancia en su poesía: la negación (y/o la incertidumbre). Si leemos con atención su obra, caeremos en cuenta de que muchos apartados contemplan palabras como «no», «ni», «nunca», «sin» o, en su defecto, «alguien», «algo». </p>
<p>Esto ocasiona que varias de las secciones de la escritura de Rodinás mezclen ideas contrapuestas, mediadas por su eclecticismo, provocando una incertidumbre que podría llevar a la paradoja<sup>15</sup>. Debido a esto el tópico al que más recurre el poeta es la (ir)realidad porque al intentar yuxtaponer la una sobre la otra, le es inevitable no crear una paradoja de la existencia misma, de la escritura <i>per se</i>. Dado que el espacio es limitado, solamente utilizaré un fragmento del poema «La vida como esa experiencia chispeante y burbujeante que ves en los anuncios de gaseosas», de <i>Cuaderno de Yorkshire</i>, que dice:</p>
<p class="p-all-init-space">¿El agua escribe lo que no escribo. <br/>
Yo, en cambio, escribo esto para poder borrarme, <br/>
para debilitarme, para encontrarme paradojas absurdas <br/>
(como el tipo que pide una sopa de fideo <br/>
y luego exige que la traigan un desayuno completo) <br/>
para escoger las fracciones más útiles de mí y tirarlas al basurero.</p>
<p>La escritura como método de borradura del ser, de debilitamiento, de negación. La poesía como ese lugar donde la existencia puede darse sin un sitio determinado, cambiarse de lugar o dirigirse contracorriente del flujo normal de circulación. El poema como un artefacto que erige su sentido en la imagen que busca transgredir los límites de la mente y formular universos alternos, de una oniria pictórica, en los cuales el lector pueda extraviarse y cuestionar qué tan <i>real</i> es su realidad. La obra del ecuatoriano es un viaje a través de la (ir)realidad porque solamente le interesan los mundos que exceden este mundo, aquellas imágenes que concilian lo irreconciliable. Rodinás es un poeta que contempla lo inmóvil de aquello que está, fatalmente, condenado al movimiento: la poesía. </p>
<p>Para cerrar este <span class="text-decoration-line-through"> (brevísimo)</span> acercamiento a la obra de Rodinás, quiero citar este fragmento del poema «Dollboy filmado por Giorgio de Chirico», del libro <i>Código de barras</i>, porque creo que en él se condensan gran parte de los puntos que decidí tocar en estas páginas. Sin más que agregar, por el momento, con estos versos, me permito darles la bienvenida a <i>esta</i> —quizá única— realidad que lleva por nombre <i>Amplitud modulada</i>.</p>
<p class="p-all-init-space">¿Realidad sí. Realidad con llantas. <br/>
Alguien sueña un volante encendido. <br/>
Alguien piensa con su mano: velocidad<br/>
Para poner la cabeza en el suelo<br/>
y que se acelere la vida.</p>
<div class="ref-container">
<div class="box"></div>
<p class="reference"><sup>1</sup> El otro libro compilatorio de su obra es <i>Koan Underwater</i> (Cardboard Press, EE. UU., 2018); sin embargo, este se diferencia de los dos mencionados porque fue una edición bilingüe que contó con la traducción al inglés de Ilana Dann Luna.</p>
<p class="reference"><sup>2</sup> Cabe resaltar que <i>El blues de las esferas</i> es una obra que permanece inédita como libro en solitario. Aunque sí fue antologada en <i>Los páramos inversos</i> (2014), como parte de la obra reunida —hasta ese momento— de Rodinás, nunca se publicó como poemario íntegro en una edición singular fuera de una antología.</p>
<p class="reference"><sup>3</sup> Es necesario advertir que no solo la pintura es parte del eje visual significativo para la obra del poeta ecuatoriano. En una entrevista, realizada en el diario <i>El Universo</i> en 2019, Rodinás manifestó: «Hice mi primer poema sobre un cuadro en 2003. Era algo sobre Henri Matisse. De ahí en adelante, creo que cada uno de mis libros incluye algún texto sobre pintura, fotografía, fotogramas de cine o afiches». </p>
<p class="reference"><sup>4</sup> En la antología se encuentran referencias a los artistas de la imagen: Caspar David Friedrich, Bansky, Hayao Miyazaki, Giorgio de Chirico, Chaim Soutine; de la música, Freddy Mercury, Janis Joplin, Guns n’ Roses, John Coltrane, Thelonious Monk, Billie Holliday, Miles Davis; de la danza, Vaslav Nijinsky; de la poesía, Philip Larkin, José Watanabe, Alejandra Pizarnik, Robert Creely, Paul Muldoon, Ted Hughes, Tomas Tranströmer; y la filosofía, Blaise Pascal y Jacob Böhme.</p>
<p class="reference"><sup>5</sup> Quince textos constan en ambas antologías: «Balada para un perdedor absoluto en verso relativo», «Hapinness, finale», «Joy. Segundo sueño. ¿Final? Septiembre 2011», «Ilustración con muchacha dentro», «Almacenes, almacenes», «Dollboy filmado por Giorgio de Chirico», «Canción de autor. <i>En la iglesia evangélica de la eternidad</i>, 2008», «Pequeño ultravioleta dice. <i>Septiembre 1</i> 2008 12:00-14:16», «Axones. <i>Canción de despedida, de llegada</i>», «Homenaje a Paul Muldoon», «Las oraciones del trovador ciego…», «Junto al tapiz de agua se reúnen…», «Territorio para un cuadro de Soutine», «Lugares del río imaginado» y «Transformer Tranströmer». Los nuevos textos antologados, pertenecientes a ese período de escritura, son: «País punto», «Tachadura: hipersoneto poshumano», «Cromo postsiquiatría páramo y sintaxis penúltimo lugar» y «Hommages».</p>
<p class="reference"><sup>6</sup> <i>Estereozen</i> obtuvo el Premio Internacional de La Lira (Cuenca, Ecuador, 2013); Cuaderno de Yorkshire, el Premio Margarita Hierro (Getafe, España, 2017) y el Premio de Poesía Jorge Carrera Andrade (Quito, Ecuador, 2017); <i>Yaraví para cantar bajo los cielos de norte</i>, el Premio Casa de las Américas (La Habana, Cuba, 2019); y <i>Un hombre lento</i>, el Accésit del Premio Internacional de Poesía Gastón Baquero (Madrid, España, 2018), y el Accésit del VI Premio Internacional de Poesía Pilar Fernández Labrador (Salamanca, España, 2019).</p>
<p class="reference"><sup>7</sup> Con la excepción de <i>Anhedonia</i>, libro que fue escrito antes del período en Leeds. Además, este libro no se ve influido por el eje temporal-espacial que sí poseen los otros libros que le siguen.</p>
<p class="reference"><sup>8</sup> En su tesis de doctorado, trabajó la poética y su relación con el paisaje, nacionalismo y cosmopolitismo en la obra de los poetas uruguayos Eduardo Espina y Eduardo Milán, y los ecuatorianos Fernando Nieto Cadena e Iván Carvajal.</p>
<p class="reference"><sup>9</sup> Su poesía presenta varias referencias a la música en lengua inglesa, sobre todo, del rock de los 80. Aquí es Freddy Mercury, pero también podría ser Tom Yorke (en <i>Cromosoma</i>) o Tom Waits (<i>Cuaderno de Yorkshire</i>), por citar dos ejemplos.</p>
<p class="reference"><sup>10</sup> Me gustaría mencionar la serie de poemas «Autopista a Cayambe…» de <i>Cromosoma</i>, no incluidos en esta antología. En ellos, si bien el interés es la región andina, nos ayudan a repensar estas condiciones climáticas y geográficas que tanto llaman la atención de Rodinás y que se emparentan con las imágenes de los bosques y las colinas del Reino Unido. </p>
<p class="reference"><sup>11</sup> Como dato adicional, Rodinás publicó la antología <i>Una cosa natural. 29 poetas norteamericanos </i> (Quito, 2009). También ha colaborado con algunas revistas latinoamericanas traduciendo poesía de autores en lengua inglesa. </p>
<p class="reference"><sup>12</sup> Nota un poco <i>fuera de lugar</i>, tal como un poeta andino en Reino Unido: alguna vez, hablando sobre el mundial de fútbol, Rodinás me dijo —medio en broma, medio en serio— que su final en el mundial de poesía sería Inglaterra <i>vs. </i> Uruguay.</p>
<p class="reference"><sup>13</sup> Las imágenes mostradas con más insistencia en esta selección antológica son el automóvil y el tren (en cuatro y seis poemas, respectivamente).</p>
<p class="reference"><sup>14</sup> Como en el poema «Lugares del río imaginado», de <i>Viaje a la mansedumbre:</i> «Al dibujarse un río, debiera ser portátil / porque solo la mente conserva su sonido / al chocar en las rocas»; o en «Axones. <i>Canción de despedida, de llegada</i>», de <i>Barrido de campo</i>: «Tengo un clavo en la mente: una herramienta de luz manchada o sucia. Por ella, el ruido de los automóviles es mi fonética del mundo: carros en una larga fila de carros en una larga fila de carros atascados». </p>
<p class="reference"><sup>15</sup> En este fragmento del poema «Bansky en Miyazaki / (¿Desde cuándo mi vida es un anime con números y letras?)», de <i>Yaraví para cantar bajo los cielos del norte</i>, también se puede apreciar una posible paradoja: «Si el correcaminos muere en la mente del coyote, / el coyote ya no tiene camino». La noción de paradoja de Rodinás casi siempre está mediada por la imagen mental (y escritural) donde todo lo inexistente puede existir. </p>
</div>

`;