export const criticalStudy6 = `
<h2>El lenguaje como protagonista</h2>
<p class="author-name">Por Andrés Cadena</p>
<p>Los cuentos de Edwin Alcarás provocan la extraña sensación de estar ante algo que reconocemos lejanamente pero que hemos olvidado de alguna manera, como si hubiera sido dejado de lado en nuestra interior parcela de silencio. Esto podría desprenderse de los varios referentes de la cultura popular, sus atmósferas barriales o sus personajes urbanos, a menudo callejeros, irremediablemente buscavidas. Claro que estos elementos configuran unas voces que se construyen en un ámbito donde convergen coloquialidad y retórica, en vuelos tan cercanos al habla popular como a la poesía; pero no son ellos los que logran ese ánimo de diluida familiaridad que tanto nos intriga en estos cuentos. Quiero decir, no son los artilugios de las tramas ni la profundidad psicológica de los personajes lo que opera en estos textos un efecto hipnótico en quienes leemos. </p>
<p>El principal personaje de estos cuentos es, más bien, el lenguaje. Si seguimos la idea tradicional de que, en una narración, las acciones son manejadas por los personajes, es inevitable constatar que en los cuentos de Alcarás los giros ocurren menos en el nivel de la anécdota que en el de los hallazgos discursivos. Los narradores protagonistas de Alcarás se erigen sobre el postulado psicoanalítico de que el inconsciente humano funciona como un texto, está hecho de palabras: las historias que nos cuentan se les salen de las manos y el curso de sus existencias, aunque contado en su propia voz, los desborda. En ese giro, pese a ser protagonistas de su relato, es el lenguaje en sí el elemento que se desenvuelve a contrapelo de sus intenciones, y ellos terminan «siendo dichos» por esa materia viva que proviene de sus interiores pero que los rebasa. </p>
<p>En efecto, al inicio del cuento «Zombis» el narrador protagonista expresa el dolor que siente tras la separación de su mejor amigo por causa de la muerte: «Francisco, hoy tampoco llegaste a la esquina. No encendiste tus cigarros gigantes con ese orgullo tuyo dulce, de hermano. No esperaste a nadie. Nadie celebró a risotadas tus chistes sobre maricones»; y al final del párrafo, el ritmo propio del lenguaje aliterado desemboca en este giro: «Nadie soy yo, loco. Nadie es el fantasma que soy yo y que tampoco va a la esquina, bróder», en donde se conjuga tanto el sentimiento del personaje cuanto la musicalidad repetitiva de las frases cortas, y también el guiño (hasta muy lejos de esta realidad de barrio) a la aventura de Odiseo frente al cíclope Polifemo, cuando se presenta como «Nadie» ya pensando en su próxima huida, en medio del anonimato. Todos esos niveles se conjugan en la prosa de Alcarás y abren diálogos entre los variados registros que animan la escritura. </p>
<p class="highlight">En los cuentos de Alcarás los giros ocurren menos en el nivel de la anécdota que en el de los hallazgos discursivos.</p>
<p>Justamente, un hilo argumental que aparece con recurrencia en las narraciones de Edwin es la escritura misma. Hay que ir más allá del diálogo intertextual con las menciones a varios autores, los parafraseos (los tributos de estilo que en ciertos pasajes podemos reconocer) y la presencia de epígrafes en sus distintos cuentos: aquí la escritura —y la literatura, claro— son símbolos que se recargan y resignifican en función de la tensión que establecen los personajes entre esa pulsión creativa y la circunstancia existencial en que se encuentran. Para decirlo en un modo cercano a nuestro autor: cuando en las tramas de estos cuentos nos encontramos con escritores, no nos encontramos con escritores sino con su vacío. Con su estela, quizá, ese espacio que es como una invitación para ocupar el sitio de otro. </p>
<p>Allí tiene cabida una visión profundamente irónica (por momentos, socarrona) sobre la figura del escritor, como en el cuento «Un poema inconcluso», cuyo protagonista es José Ignacio Alcántara, el ficticio «ecuatoriano universal», único autor de nuestro país con una importancia literaria comparable a la de Borges. Muy pronto se despierta nuestra suspicacia: su primera obra se titula <i>La montaña trágica</i>, «una ágil narración de índole nietzscheana, según explicaba Alcántara, que imitaba los fundamentos arquitectónicos y éticos de <i>La montaña mágica</i>, de Thomas Mann». Los delirantes recovecos de la aventura que vivirá parten de esta base caricaturesca de Alcántara, en cuyo apellido no podemos dejar de escuchar una lejana reminiscencia del apellido del propio Edwin, que se pone de ese modo a sí mismo bajo la lupa de la cómica sospecha. </p>
<p>Una visión un tanto distinta sobre la escritura es la que se presenta en el relato «Vía Cariño», en donde el narrador protagonista ha ido hasta la costa en busca de un fabuloso y ermitaño novelista nacional cuya obra ha conocido por azar. El cuento entero es un desvío, es lo que le ocurre y los encuentros que tiene el narrador mientras busca infructuosamente a Pacífico Nil —otra vez, la prosa de Alcarás está repleta de guiños que se disfrazan de excentricidades cotidianas—. En correspondencia con tal búsqueda, el efecto retórico se centra en construir la ausencia: el narrador se habla a sí mismo en segunda persona, como abriendo un hueco en su discurso y en su psiquis; cuando leemos sus frases temerosas, dubitativas y llenas de secretos, escuchamos el retumbar de un eco en el vacío nihilista (¿nietzscheano?) que nos propone el texto: </p>
<p class="p-all-init-space">Cómo explicarles esa desesperación, esa bronca, ese miedo de que tal revelación sublime no fuera más que otro embuste de tu conciencia resquebrajada, otra patraña de tu exhausto sentido de la realidad. Cómo decirles que te sentías miserable desde entonces, que Nil había sido un garrotazo tan furioso que si hubiera sido cocaína habrías vuelto directo a la clínica de desintoxicación, que era en cierto sentido lo mismo, aunque en otro sentido era más bien lo contrario…</p>
<p>Ese desvío existencial, la empresa que no se cumple o la meta que no se alcanza (recordemos que el primer libro de cuentos de Alcarás lleva por título <i>La tierra prometida</i>) reaparece en el relato «Se busca verificador». Aquí, nuevamente será la escritura un medio para no llegar a ningún lado, un camino en círculos o, paradójicamente, una vía sin sentido. Leemos el supuesto diario de un verificador de datos, un hombre cuyo oficio es el sistemático examen de la relación entre lo que se dice (un discurso) y la verdad (un dilema filosófico). Se remarca la alegoría presente en los cuentos anteriores: el espacio del sujeto es cooptado por el lenguaje, que sucede con un ritmo inmanente aunque no conduzca a ningún paradero claro: </p>
<p class="p-all-init-space">La eficiencia en mi trabajo significa no existir. Yo, personalmente, soy muy eficiente. Me traen listas colmadas de cifras, supervisadas por fechas, coronadas de nombres que uno siempre debe creer dudosos. Pero solo por costumbre, porque todos dicen la verdad. O sea, al menos nadie se inventa nada. Por otro lado, que no se inventen nada no significa que digan la verdad. </p>
<p>A partir de estas premisas, nos vamos adentrando en una empresa tan concreta como absurda y relativa: tan ambigua como increíble. Al igual que lo que le ocurrirá al protagonista, que se verá día a día envuelto en una interacción con unos desconocidos que implicará incluso una muerte. El estatuto que se pone en cuestión es la existencia misma, en un sentido cartesiano: me doy cuenta que pienso, pero lo que vivo es imposible de verificación, podría ser parte de la reflexión de este personaje, cuyo relato termina en una oscuridad sicológica plena de posibilidades de interpretación. Quizá este verificador esté emparentado con el agrimensor de Kafka en <i>El castillo</i>, quien nunca llegará a cumplir con su papel. </p>
<p class="highlight">Cuando en las tramas de estos cuentos nos encontramos con escritores, no nos encontramos con escritores sino con su vacío. Con su estela, quizá, ese espacio que es como una invitación para ocupar el sitio de otro.</p>
<p>La constelación de influencias de Alcarás, como vemos, es algo a lo que sus textos remiten en varios niveles de interpretación y que redimensionan su escritura. Borges, Kafka, Mann, por ejemplo, nos abren a un marco de referentes mayores y también caros para Alcarás: Dante, Nietzsche, Coleridge, Homero. Pero la red de relaciones no se constriñe al tributo (o la ajustada asimilación de estos clásicos por parte del autor), sino a la concepción del lenguaje como una estructura tapizada del palimpsesto de la tradición. Es decir, la metarreferencialidad en estos cuentos no busca evidenciar el rastro de una originalidad, sino contribuir a la puesta en duda de la idea de un original. Así, como varios de estos autores de los que abreva Alcarás, el tono onírico (o metafísico o fantástico o surreal, según la época) en que devienen sus tramas nos remite a las preocupaciones de un ensayista que imagina tramas. Así, por ejemplo, en «La muerte duplicada» leemos: </p>
<p class="p-all-init-space">No me atrevo a abrir los ojos. Sé que, fuera de mí, la vida sigue rodando entre los hombres como un mal sueño o como un lugar común. Si ya estoy muerto no quiero confesármelo. Esta horizontalidad es el principio y el fin de mi humanidad. Soy mi propia fantasía. Sueño. ¿O esto es la realidad? Sueño que soy otro. Me pregunto si el otro que soy en mis sueños también estará soñando que es otro. </p>
<p>Como lo señalamos más arriba: el coloquialismo de varios narradores de nuestro autor da la impresión de echar mano de un costumbrismo que no es tal (como en Juan Rulfo), sino que proviene de las estructuras lingüísticas operando sus lógicas en el subconsciente de los personajes. Allí se dan asociaciones involuntarias, equívocos y anécdotas sorpresivamente confluentes como en los cuentos «Joaquín» y «Don Luis» (este último, inédito hasta ahora), en donde los afectos de los protagonistas van surgiendo de modo tan oblicuo como sincero, como si su inevitabilidad fuera otro nexo más con esas angustias existenciales que pueblan los universos narrativos de Alcarás. En el breve relato «Joaquín», el narrador lamenta la muerte de su compañero: </p>
<p class="p-all-init-space">¿Quién hubiera visto tu cadáver si yo también me moría? Y al revés menos. Vos ni con tus propias carnes avanzabas, peor ibas a poder con un muerto. Lo que importa es que estamos aquí los dos con la muerte. Con tu muerte. Es suficiente para los dos. De pronto sobra. Esto nos va a unir para siempre. </p>
<p>En donde es imposible no recordar, como mencionamos, a Rulfo, otro autor canónico cuyas narraciones alegorizan un realismo descarnado a fuerza de cincelarlo con giros muy locales, expresiones del inconsciente colectivo que sintetizan anónimamente cosmovisiones enteras sobre los asuntos más acuciantes para la condición humana. </p>
<p class="highlight">La metarreferencialidad en estos cuentos no busca evidenciar el rastro de una originalidad, sino contribuir a la puesta en duda de la idea de un original.</p>
<p>Rindamos otro homenaje a Alcarás imitando su tono, y digamos que es un cuentista que parece novelista que parece ensayista que parece un cronista que inventa lo que escribirían todos los anteriores. Es alguien a quien se le va la vida en la disputa con el lenguaje por el derecho de tomar la palabra; y cuyo sino fatal consiste en tener conciencia de todas las voces que hablan a través de sus palabras, y no encontrar la suya. No es que escriba para encontrarse, sino que sabe que no puede más que perderse: abandonarse a los callejones sin salida de las paradojas, del oxímoron, de las infinitas imágenes especulares que proyecta cualquier escritura que hable de la escritura. Sus textos nos invitan siempre a interpretar, a involucrarnos, a sumirnos en el mar que es el lenguaje. Quien habla en estos cuentos no es Nadie, como se nos sugirió en algún punto; sino el canto de las sirenas, esos seres mitológicos que contaban todo lo ocurrido y todo aquello que iba a ocurrir, embelesando así a los aventureros. Alcarás no nos promete el prodigio de la adivinación, sino la posibilidad de lo imposible: cuando nos metamos en las tramas de sus personajes, en sus vidas y discursos, estaremos entrando al infinito terreno de la ficción. Y nos daremos cuenta de que todo lo que no estaba allí antes, ahora lo está. </p>
`;