import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';

import { BackButton } from '../ui/elements/BackButton'

import { appRoutes, shortRoutes } from '../../utils/constants'
import { authors } from '../../utils/data'

import './interview.scss'

export const Interview = () => {
    const history = useHistory();
    const { idAuthor } = useParams();
    const [currentAuthor, setCurrentAuthor] = useState()


    useEffect(() => {
        if (idAuthor) {
            const currentAuthor = authors.find(author => author.id === idAuthor)
            setCurrentAuthor(currentAuthor)
            ReactGA.pageview('Entrevista ' + currentAuthor.name);
        }
    }, [idAuthor])

    const goToPage = (authorId, page) => {
        let route = shortRoutes.author;
        switch (page) {
            case 1:
                route = shortRoutes.criticalStudy;
                break;
            case 2:
                route = shortRoutes.interview;
                break;
            case 3:
                route = shortRoutes.book;
                break;
            case 4:
                route = shortRoutes.teachers;
                break;
            default:
                break;
        }
        history.push(`${route}${authorId}`);

    }

    return (
        <>
            {
                idAuthor ?
                    <div className="screen-interview">
                        {
                            currentAuthor &&
                            <div className="container-fluid">
                                <BackButton />
                                <div className="row">
                                    <div className="col">
                                        <p className="text-center title m-0">Entrevista a</p>
                                        <h1 className="text-center">
                                            {currentAuthor.name}
                                        </h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="container">
                                            {
                                                currentAuthor.interviews && currentAuthor.interviews.map((interview, index) => {
                                                    return (
                                                        <div className="col-12 d-flex flex-column align-items-center mt-5" key={"interview" + index}>
                                                            <p className="w-75">
                                                                <strong dangerouslySetInnerHTML={{ __html: interview.text }}>
                                                                </strong>
                                                            </p>
                                                            <iframe width="560" height="315" src={interview.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-book-link py-5 mt-5">
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex flex-column justify-content-center align-items-center mx-5">
                                            <img className="hex-img-link mini" src={`/images/${currentAuthor.imgCriticalStudyHex}`} alt="Estudio crítico" title="Estudio crítico" />
                                            <button className="btn btn-outline-dark mt-3" onClick={() => goToPage(currentAuthor.id, 1)}>
                                                Estudio crítico
                                            </button>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-center mx-5">
                                            <img className="hex-img-link mini" src={`/images/${currentAuthor.imgBookHex}`} alt="Ver libro" title="Ver libro" />
                                            <button className="btn btn-outline-dark mt-3" onClick={() => goToPage(currentAuthor.id, 3)}>
                                                Libro
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <Redirect to={appRoutes.home} />
            }
        </>
    )
}
