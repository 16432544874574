export const criticalStudy4 = `
<h2>Espejo:<br/> <small>el monstruo entre los barrotes soy yo</small></h2>
<p class="author-name">Por Alejandra Vela Hidalgo</p>
<p class="text-end"> Tras los fuertes barrotes la pantera<br/>
repetirá el monótono camino<br/>
que es (pero no lo sabe) su destino<br/>
de negra joya, aciaga y prisionera. <br/>
«La pantera», Jorge Luis Borges</p>
<p>El felino caminando de un lado a otro en una jaula del zoológico en Buenos Aires obsesionaba a Borges, quizá porque le mostraba, en un reflejo terrible, el destino ineludible del ser humano y de él mismo. Parece que Mauricio Montenegro también ha vislumbrado la jaula como un reflejo de su destino y el de sus personajes. En este cuerpo de relatos, habla de aquellas jaulas en las que nos reflejamos en el animal encerrado, en el otro, que camina de aquí para allá. En estas narraciones, habitan seres que ven sus realidades con ironía, sarcasmo y una dosis de desprecio. Se burlan de la sociedad y de ellos mismos, de sus debilidades de mente y cuerpo; sin embargo, muestran una profunda soledad existencial, como atrapados solos en jaulas: es el destino del que no pueden huir. La complejidad psíquica de los personajes se sustenta en un juego de reflejos que se produce entre ellos; se miran a través de los barrotes los unos a los otros enjaulados, viéndose a sí mismos, y se forma así una red entre cuerpos, mentes, sujetos y sociedad. </p>
<p>Uno de los temas que con mayor profundidad se trata en los relatos es la mirada de la sociedad normalizada hacia el cuerpo con discapacidad. En «Transporte escolar», el Trooper se presenta como un microcosmos de la sociedad, en el que la infancia se constituye en el sitio de la maldad, pero también y sobre todo de la inocencia infinita. Tres niños de los que no se menciona el aspecto de su físico, puesto que encarnan la «normalidad», abusan de otros cuatro, más bien débiles, cada uno a su manera: La Sorda, una niña sorda y albina que no puede hablar; Francisco, el de los mocos; Petrita, la niña que se marea, y el protagonista; ellos se vuelven el blanco de los abusos de los «normales». El maltrato mayor le llega a La Sorda, pues es sobre quien el poder ejerce con más saña su violencia: el cuerpo femenino con discapacidad, doblemente doblegado por los que momentáneamente tienen el poder (cuando el chofer se baja del auto a hacer una diligencia). El abuso presenta una violencia específica sobre su cuerpo femenino: le bajan los calzones y exponen su sexo. Significativamente, La Sorda no puede hablar: emite sonidos animalescos, inentendibles mientras los otros la miran. Su silencio metafóricamente es el de los subalternos, que es de un desamparo irremediable. El Trooper es la jaula de la jauría de niños, en la que se reproduce la dinámica del poder sobre el cuerpo subyugado.</p>
<p class="highlight">En estas narraciones, habitan seres que ven sus realidades con ironía, sarcasmo y una dosis de desprecio. Se burlan de la sociedad y de ellos mismos, de sus debilidades de mente y cuerpo.</p>
<p>En «Niño que llora», el grito de un niño con discapacidad altera la tranquilidad de una pareja. No es el niño en sí lo que les molesta, sino la parte que se percibe: su grito, sonido aterrador. Es un ruido inidentificable que perturba el trabajo de los narradores en su «proyecto». El grito constante altera su vida «alternativa» de meditación, proyectos y sexo. El uso del «nosotros» como un narrador colectivo da la sensación de que el grito trastorna la tranquilidad de una comunidad, la sociedad normalizada, que a pesar de que intenta encontrar la fuente del grito para calmar al emisor, finalmente, lo elude como una contrariedad más en su vida perfecta. La búsqueda de una nueva casa es la actitud que tenemos frente a la discapacidad: virar el rostro para no ver ni enfrentar la discapacidad, gesto que hace que no nos veamos en el grito del niño, sino en la pareja que quiere seguir su vida «normalmente alternativa».</p>
<p>Sin embargo, estas narraciones de Montenegro insisten en mostrar la discapacidad sin tapujos. Las descripciones de los niños que acuden al Campamento Esperanza, por ejemplo, presentan estos cuerpos sin filtro, aceptándolos tal cual a través del humor proveniente de la mirada infantil del protagonista, que ve la situación con ingenuidad y sin prejuicios: «Cantamos atropelladamente, los que podíamos, otros solo balbuceaban frenéticos, y aquellos que teníamos brazos y manos acompañamos a la mujer con aplausos». Así, la mirada infantil insiste en enfrentar al lector con el cuerpo con discapacidad: </p>
<p class="p-all-init-space">A mi lado se encontraba una niña que, al igual que mi amigo, estaba en una silla de ruedas, pero a diferencia de él, se desparramaba flácida en ella, la cabeza laxa colgaba hacia atrás en un ángulo imposible. Le pregunté cómo se llamaba y respondió con un aullido animal y una metralla de babas. Era imposible jugar con ella.</p>
<p class="no-indent">Vemos el cuerpo de la niña con la misma ingenuidad del protagonista que lo describe como a cualquier otro, sin tapujos ni intentos de ser políticamente correcto; además, expresa con naturalidad su preocupación por la imposibilidad de jugar con ella. Los cuerpos simplemente son, con sus complejidades y contradicciones. Así, por un lado, los lectores nos vemos en la pareja de «Niño que llora» virando la mirada para eludir el grito, pero, por otro, nos vemos observando con curiosidad a la niña en la silla de ruedas.</p>
<p>«Campamento Esperanza» desenmascara los discursos sociales sobre la discapacidad. El mural de un Cristo rodeado de gente con diferentes discapacidades es bastante decidor; es como el gringo que cuida del narrador y su compañero: les da de comer en la boca y les cuida cuando están enfermos. Es un discurso en el que las personas con discapacidades deben ser salvadas o curadas, en cuanto son seres indefensos, visión bastante cándida y simplista que contrasta con la complejidad que se presenta en el relato. Los niños que van al campamento cantan, participan de juegos y dinámicas. El protagonista disfruta de estas actividades hasta cierto punto, pues tiene apenas seis años. Sin embargo, Darío, su compañero de cuarto, parece no divertirse mucho; hay algo en él que no calza en ese mundo idealizado de cantos y rezos:</p>
<p class="p-all-init-space">Un niño feo, sin duda. Era casi de mi estatura… y casi del doble de mi grosor. De su cara redonda sobresalían unos ojillos entrecerrados pero saltones, que lo escudriñaban todo con desdén. El pelo era cobrizo, escaso. La piel, trigueña, aunque algo rosada en las mejillas. Pero lo que más me llamó la atención de Darío fue esa sombra, el amague de un bigote, que se dibujaba encima de su labio superior.</p>
<p class="no-indent">El pequeño de seis años siente que se pasa mejor con este «niño» que insulta y gruñe que con el grupo de entusiastas cantadores. Le cuenta sus historias y trata de jugar con él, pero Darío es apático, mal humorado y sarcástico. En realidad, es un adulto atrapado en un cuerpo infantilizado por la sociedad (nótese la sombra del bigote en la descripción). Aquel niño, el protagonista, que recuerda esta historia desde su adultez, se está reflejando en Darío. Se ve, desde su presente adulto, con la misma actitud irónica y sarcástica sobre el campamento y la sociedad que hubiera tenido Darío. Darío es una proyección del protagonista. El reflejo concluyente entre los dos se produce en el laberinto cuando el niño se encuentra con el minotauro (Darío en su silla mientras el gringo lo empuja bajo la lluvia). La discapacidad se deconstruye en el laberinto, pues deja de ser esa mirada simplona del discurso cristiano y más bien se presenta como una compleja red (laberíntica) de significados, en la que se entretejen la inocencia, el sarcasmo, la adultez, la mirada del otro, los complejos, los recuerdos…</p>
<p>En «La Cosa», Montenegro crea una voz femenina: una reclusa condenada por el asesinato de su esposo (quien a su vez hizo algo a su hijo), que siente que debe vengar a La Cosa o Serena, su nombre posterior, una niña supuestamente maltratada y abandonada por su madre. La voz llena de ira de la narradora, que es la única en el relato, juzga duramente a la Madre Monstruo, la culpable del autismo en la niña. La narrativa es de una violencia apabulladora que no permite cuestionamientos, y que se refleja en los epítetos de la madre: Madre Tirana, Madre de Mierda, Madre Gusano, Madre Infame. La narradora la golpea en la cara, mientras ella, la madre de La Cosa, aguanta en silencio: «La Madre de Mierda sencillamente se dejó bofetear, golpear y patear. Cuando me cansé de humillarla, le hice todas esas preguntas que no le habían hecho y muchas otras, sin obtener respuestas». En su silencio y pena, la Madre Monstruo no se explica ni excusa. Es una mujer proyectada a través de la violencia y la culpa en torno a su maternidad; no tiene identidad más allá de su condición de madre fracasada. El padre, en tanto, carga con un peso muchísimo más leve de culpa por el abandono de la hija. </p>
<p>Una lectura entre líneas descubre que La Cosa tal vez no sea todo culpa de su madre y que la narradora está imponiendo su visión sobre la realidad (ajena al lector). Quizá la tristeza de la madre, su silencio ante las acusaciones y los golpes, la declaración del padre de que la madre amaba a su hija, los otros hijos bien cuidados y, finalmente, el nombre de la niña, Serena, sean una señal de que la Madre Monstruo no es tan monstruo. Quizá su silencio diga más que la violencia de las palabras sobre una madre que lleva la cruz, como su apellido: Da Cruz. A la reclusa, en su prisión, la vemos desde afuera, como lectores, pero refleja una sociedad actual de la que somos parte, que condena y pone todo el peso de la culpa sobre las madres que deben cargar con la cruz de la vida de sus hijos. </p>
<p class="highlight">La discapacidad se deconstruye en el laberinto, pues deja de ser esa mirada simplona del discurso cristiano y más bien se presenta como una compleja red (laberíntica) de significados, en la que se entretejen la inocencia, el sarcasmo, la adultez, la mirada del otro, los complejos, los recuerdos…</p>
<p>Los barrotes en estos relatos están por todas partes; en «Maratonista», encontramos a un narrador testigo que imprime su personalidad al describir las rutinas de su vecino a quien mira desde la ventana. El narrador, con tono sarcástico, pero también un poco amargado, observa cómo el vecino se va convirtiendo en un deportista que lleva una vida sana. Así como el vecino se vuelve saludable, el narrador se va descubriendo como un habitual voyerista desde su silla frente a la ventana con el gato sobre la falda, quien, al espiar y criticar al otro, se erige como un reflejo opuesto al vecino. Las ventanas del departamento, sus propios barrotes, se plantean como los barrotes de las ventanas de las redes sociales, en las que el vecino publica sus logros y aparenta tener una vida perfecta. Así como el narrador disfruta de espiar al vecino, los lectores convertimos al texto en la nueva ventana para no solo espiar la vida del vecino, sino del mismo protagonista. Hay un juego de reflejos que devuelven la imagen invertida, pues el narrador se construye como personaje en su propia amargura que surge del fastidio que le produce la vida insuperable del otro. La ironía y el sarcasmo del discurso, aquella disfrutable amargura, son claves para vernos a nosotros mismos espiando por las ventanas de las redes sociales a aquellos que muestran sus exitosas vidas, mientras las nuestras siguen su normal patético curso.</p>
<p>La jaula del animal monstruoso en el acuario, en «Jaulas», es la imagen que metafóricamente nos habla del protagonista. El monumental ser, como el narrador, está ciego y vive encerrado en su inmunda piscina. Mientras avanza la historia, se va revelando la verdadera jaula y el verdadero monstruo: la falsa idealización de un amor pasado que nunca sucedió y que espera inútilmente. El santuario de pájaros le muestra al narrador que ella ha superado el conflicto cuando, a pesar de su fobia a las aves, logra atravesarlo; en cambio, él se queda inmovilizado en el espacio cerrado mientras contempla la imagen de la quimera: la chica mimetizada en el pavo real con su cola de cien ojos formando un solo ser. El protagonista está varado (enjaulado) en la nostalgia de un pasado que jamás sucedió. El animal de la jaula y la quimera son los reflejos metafóricos de lo que le sucede. Algo similar pasa en «Salud dental», narración en la que el personaje se arma una historia con su compañera de cuarto sin que la involucrada se entere. Los pensamientos, en ambos relatos, se vuelven imposibles de superar hasta que la realidad rompe la ilusión de una bofetada. </p>
<p>La nostalgia por lo no vivido que está presente en «Jaulas» también la encuentro en «Animales de campo». La pérdida de un padre que ha abandonado al protagonista cuando era niño es el luto por el padre ausente, por aquel que no estuvo. Cuando de niño miraba el cuerpo de su padre, le preguntaba si el tendría la misma fuerza, y el padre le contestaba que sí; sin embargo, el cuerpo del protagonista es el opuesto al del padre, más bien débil y poco atlético: «Ese cuerpo insectoide que se reflejaba en el espejo jamás iba a albergar ningún músculo prominente y el esfuerzo de levantar las pesas me producía ampollas y sangrados». El reflejo en el espejo no es la imagen del padre, sino un falso reflejo. </p>
<p class="highlight">Hay un juego de reflejos que devuelven la imagen invertida, pues el narrador se construye como personaje en su propia amargura que surge del fastidio que le produce la vida insuperable del otro.</p>
<p>La oveja degollada que busca al niño (historia de la infancia del padre) para morir en su regazo es un evento paralelo a la escena del padre que agoniza con el hijo (el protagonista). Ahora, en el presente narrativo, el hijo sostiene al padre que muere, como una oveja perdida. </p>
<p class="p-all-init-space">Los brazos bien extendidos a la altura de los hombros, prácticamente desnudo, el calor del ambiente y el que emanaba de su propio cuerpo lo estaban cocinando, las piernas de musculatura consumida bien rígidas, la cabeza torcida en dirección a la ventana, de tal forma que los ojos podían ver cómo el sol se empecinaba en quedarse clavado en el cielo en una tarde que parecía no acabar nunca. Escuché que susurraba algo y me acerqué a él. «¿Por qué me has abandonado?». </p>
<p class="no-indent">La escena del padre reconstituye la imagen de Cristo crucificado preguntado a Dios por su abandono. Irónicamente, reclama el abandono de Dios cuando él mismo abandonó a su hijo; por eso, dice el protagonista que se sorprendió pensando él también en la misma pregunta. A pesar del abandono, no hay resentimiento hacia la figura paterna, sino mucha ternura. Con todo, dos días antes de que el padre fallezca, decide irse y lo deja morir con sus parientes con una falsa promesa de reencuentro. Se cumple así el juego de reflejos en el que protagonista termina abandonando al padre indefenso, al padre oveja herida. El luto por el padre ausente es la nostalgia de lo no vivido, de lo que pudo ser.</p>
<p>Mauricio Montenegro nos presenta estas historias de jaulas, en las que a través de los barrotes nos vemos reflejados como cuerpos, mentes, individuos y sociedad ante temas tan humanos como la discapacidad, la maternidad, el amor no correspondido, la paternidad, el luto, entre tantos otros. Los monstruos en las jaulas somos nosotros mismos: es la mirada que atraviesa el barrote y se encuentra de frente con el rostro del minotauro.</p>
`;