export const criticalStudy1 = `
<h2>Tengo insomnio, madre</h2>


<p class="author-name">Por Juan Carlos Arteaga</p>

<p>El primer contacto que tuve con la escritura de Gabriela Vargas Aguirre no fue a través de un formal intercambio de posturas estéticas; sino que, en redes sociales, uno de sus conocidos puso a circular un poema brutal. La voz poética, dejando sortear las desgarraduras que la sorprenden en la crianza de su hija, crea una atmósfera asfixiante —propia justo de la pandemia— que termina por revelar cómo escapa hacia un automóvil, en la entrada de su casa, para tomar una botella de licor escondida debajo del asiento. El texto, desde su primera lectura hasta el día de hoy, continúa golpeándome, desolándome por esa relación tan decadente con la tristeza, con el encierro, con el alcohol. Su ritmo y su textura, leído en los momentos en los que el confinamiento eran para mí una especie de tortura, marcaron su significado y los afectos que me provocó. </p>

<p>El poema dice: </p>
<p class="p-all-init-space">Mami, ¿por qué seguimos en pijama? <br/>
Explicarte verbos tristes como «encerrar»<br/>
será lo primero que te diré. <br/>
Luego, que para salir falta un número incierto de lunas<br/>
Es así el tiempo algunas veces, otras, lo medimos en tablas de mar.
<br/>
<br/>
Las horas son canciones infantiles sobre gatos que no escapan por los techos.
<br/>
<br/>
Este es el encierro, Cora, confundir las cosas hasta decir; por ejemplo: <br/>
le tengo miedo a los monos. <br/>
La canción te hizo llorar. <br/>
Te digo: vamos a salir<br/>
Te pongo tus mejores ropas, daremos vueltas a la terraza. <br/>
Serán diez. <br/>
La perra nos seguirá como el sol. <br/>
Subiremos las escaleras en un pie, te pondré tu disfraz de león. <br/>
Huiremos de los hombres que reparten los tanques de gas. <br/>
Y entonces estarás por primera vez en desacuerdo.
<br/>
<br/>
Te veo llorar…
<br/>
<br/>
Mami, ¿por qué te vas si seguimos en pijama? <br/>
Explicarte verbos tristes como «escapar».<br/>
Es lo que aprenderás cuando me veas huir hasta el carro<br/>
para coger la botella escondida<br/>
que tengo hace días debajo del asiento.</p>

<p>Gabriela Vargas Aguirre publicó este texto en Facebook. Está acompañado de una fotografía a color de su hija Cora, vestida de león y mirando de frente a la cámara. Desde entonces, he tratado de seguir su escritura, sus poemas sueltos de libre «tráfico» por internet, sus entrevistas, sus reseñas, hasta cuando, a finales del año 2020, sorprende la noticia: el poemario <i>Lugares que no existen en las guías turísticas</i> gana el premio internacional de poesía Vicente Huidobro. La editorial que se encargó de la edición es Valparaíso Editores y varios ejemplares del libro ya se encuentran en el país. <br/>
Parte de la poesía de Gabriela Vargas Aguirre se articula como una voz poética que se erige sobre su imposibilidad de estar en un lugar, que transita hacia otros espacios, hacia otras latitudes. Lo enunciado parecería ser un lugar común, pero se trata de que el lector descubra el profundo dolor que la voz poética padece con cada traslado; es decir, no solo que ninguna casa sea «su casa» sino, sobre todo, cómo se desgarra con ese devenir. El que no tenga un lugar es un símbolo de su propia errancia, de su propia tristeza, pues se siente extranjera en todo momento.
Pero en la escritura de Gabriela Vargas Aguirre —si se lee lo que estuvo antes y lo que vendrá después como el poemario <i>La ruta de la ceniza</i> publicado en 2017 y un poemario inédito dedicado a su hija— se descubre la conflictiva relación entre la voz poética y la figura maternal que la cobija; así como la figura maternal en la que ella misma se está convirtiendo. Aquel es uno de los ejes semánticos de su escritura y una de las claves que propongo para penetrar en su universo poético: la desgarradura, el amor-odio que se experimenta por la madre, cuando la voz poética se está convirtiendo ella misma en madre. <br/>
Entonces, desde aquella mirada, el no contar con una casa, el no contar con un espacio significa no tener la seguridad que la madre podría aportar. El útero materno es la gran «casa» por antonomasia, el primer espacio que se ocupa y el primer desprendimiento del que se tiene memoria —así esta sea celular—. El llanto, la desgarradura, la curación, inician en el nacimiento, el instante exacto en el que se es separado de aquel lugar. <br/>
Teresa Calderón, una de las personalidades que fue parte de la terna que premió su escritura, dice: «Estamos frente a un libro cargado de verdades, verdades afiladas como cuchillos que te rebanan en cada poema. Frente a cada poema la autora asume un rol protagónico y es la voz de cada una de las personas que han sido de alguna manera violentadas, no es solo un nombre <i>en mi carnet de identidad soy los nombres / de miles de mujeres y niñas que una vez escaparon…</i> Y así nos encontramos frente a un hablante lírico profundamente empático con el otro; el enfermo, la niña abusada, la mujer maltratada».  </p>
<p class="highlight">Se trata de que el lector descubra el profundo dolor que la voz poética padece con cada traslado</p>
<p>La escritura de Gabriela Vargas Aguirre es como la que describe la jurado: una desgarradura, un dolor y un exilio. Lo que vuelve más cruel el desarraigo es la conciencia de la voz poética —y del lector que la acompaña, que se identifica con ella y vibra con cada uno de sus versos—. No se trata solo de perder una «casa», estar en lugares que no aparecen en las guías turísticas —que es lo mismo que decir, lugares que no aparecen para la generalidad de los seres humanos—; sino que, además, se debe añadir al dolor del desarraigo: no hay adónde ir. Esto implica no solo lo que se pierde y queda detrás; sino además lo que no se tiene por delante. La voz poética deja al lector la idea de que el espacio, como la vida humana, es una imposibilidad.
Tal vez es importante abordar lo que Gabriela Vargas Aguirre dice de sí misma y de la poderosa y brutal imagen de la mudanza que ha construido como uno de los tantos centros de su poética: «Lo que se agota es lo que se ha dado por entero. Cuando dejé de buscarme en las calles, también esas palabras que ocupaban esos pasos dejaron de suceder. Cuando me dañaron hasta dejarme rota, yo debí ponerme en pie e hilar palabras para salvarme. Luego, empezaron a suceder otras cosas, bajo el compás de mi hija que cuenta nuevas historias, aunque ciertamente todavía no las entiendo. La presencia de mi hija pone fin a muchas experiencias y las vuelve retrospectivas, fantasmas desarticulados, montoncitos de tierra, hienas cojas en el desierto».  <br/>
Parte de la escritura se convierte en ese peregrinar por «lo no hallado»: el arquetipo de la madre. La casa que se busca, desde aquella perspectiva, es el útero: justo aquel espacio que jamás aparecerá en las «guías turísticas». La madre se torna espacio simbólico vacío, sustituido por versos, por la noche, por la soledad, por cualquiera de los sustitutos que la voz poética tenga a mano. Y la voz poética, entonces, teme convertirse en aquello que odia: una «madre». Pero está condenada. Ella transita —en esa relación de amor-odio— a esa misma posición. Allí el conflicto. No solo que se busca deconstruir la imagen de «madre» que se ha tenido; sino que, además, poco a poco la voz poética muta en lo que tanto anheló, en lo que tanto odió criticar y, a la vez, en lo que tanta falta le hizo. ¿Cómo será ella con su hija?, ¿repetirá patrones?, ¿creará nuevas formas de afectos que puedan escribir otras historias? El insomnio —que es lo mismo que decir el dolor— aparece en el cambio de focalización, en la transformación de esa voz: de la hija abandonada se pasa a la voz maternal; ¿presente? Es la madre, esta vez, con la oportunidad de hablar, ¿de despedirse?, ¿de permanecer cuidando? Es el insomnio que se trasmite, es el legado, un ciclo repetitivo que, en términos simbólicos, puede destruir. </p>
<p class="highlight">La madre se torna espacio simbólico vacío, sustituido por versos, por la noche, por la soledad, por cualquiera de los sustitutos que la voz poética tenga a mano</p>
<p>Como dice la poeta en sus propias palabras. </p>
<p class="p-all-init-space">Una madre aprende a conocer a sus hijos cuando duermen. No me gusta la luz, le dije. <br/>
Los niños sueñan con un sitio en el que los colores hablan, le dije. <br/>
Tengo insomnio madre, el peso de una cascada en llamas me parte la espalda. <br/>
Tengo insomnio madre, el pasar de un jaguar es la inquietud que habita mis rodillas. <br/>
Tengo insomnio madre, todos los guerreros a mi lado están poblando las cruces, que también son sus camas. <br/>
La madre no puede conocer a un hijo que no duerme, porque mis ojos se cierran cuando aparece un punto rojo en la ventana. Yo veo como descansan los muebles de la casa en las sombras, yo veo apagarse la ciudad y el drenar de las calles, y todo se vuelve pardo y quedan solo sus verdaderos habitantes: <br/>
yo habito en una cabeza que puede ver en la noche, la bruma y el humo, <br/>
yo habito bajo la piel que se muda del calor al frío cuando los días están soleados, <br/>
yo habito en la ceguera cuando las balas perforan mis pupilas y me dejan a tientas, <br/>
No duermo en las noches, madre, no me conoces, madre, me perdiste cuando hiciste un pacto con los cuervos, ahora ellos cubren mis ojos con sus alas cada vez que amanece. </p>


`;