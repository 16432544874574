import React from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Redirect
} from 'react-router-dom';

import { PublicRoute } from './PublicRoute';
import { Header } from './../components/ui/Header'
import { Footer } from './../components/ui/Footer'
import { Home } from '../components/home/Home';
import { Author } from '../components/author/Author';
import { Sponsors } from '../components/sponsors/Sponsors';

import { appRoutes } from '../utils/constants';
import { Interview } from '../components/interview/Interview';
import { Book } from '../components/book/Book';
import { Teachers } from '../components/teachers/Teachers';
import { CriticalStudy } from '../components/criticalStudy/CriticalStudy';
import { Genre } from '../components/genre/Genre';

export const AppRouter = () => {

    const uid = false;

    return (
        <div>
            <Router>
                <Header />
                <Switch>

                    <PublicRoute
                        exact={true}
                        path={appRoutes.home}
                        component={Home}
                        isAuthenticated={!!uid}
                    />

                    <PublicRoute
                        exact={true}
                        path={appRoutes.author}
                        component={Author}
                        isAuthenticated={!!uid}
                    />

                    <PublicRoute
                        exact={true}
                        path={appRoutes.interview}
                        component={Interview}
                        isAuthenticated={!!uid}
                    />

                    <PublicRoute
                        exact={true}
                        path={appRoutes.book}
                        component={Book}
                        isAuthenticated={!!uid}
                    />

                    <PublicRoute
                        exact={true}
                        path={appRoutes.teachers}
                        component={Teachers}
                        isAuthenticated={!!uid}
                    />

                    <PublicRoute
                        exact={true}
                        path={appRoutes.criticalStudy}
                        component={CriticalStudy}
                        isAuthenticated={!!uid}
                    />

                    <PublicRoute
                        exact={true}
                        path={appRoutes.sponsors}
                        component={Sponsors}
                        isAuthenticated={!!uid}
                    />

                    <PublicRoute
                        exact={true}
                        path={appRoutes.genre}
                        component={Genre}
                        isAuthenticated={!!uid}
                    />

                    <Redirect to={appRoutes.home} />
                </Switch>
                <Footer />
            </Router>
        </div>
    )
}
