import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import { BackButton } from '../ui/elements/BackButton'

import { appRoutes } from '../../utils/constants'
import { authors } from '../../utils/data'

import './teachers.scss'

export const Teachers = () => {
    const { idAuthor } = useParams();
    const [currentAuthor, setCurrentAuthor] = useState()


    useEffect(() => {
        if (idAuthor) {
            const currentAuthor = authors.find(author => author.id === idAuthor)
            setCurrentAuthor(currentAuthor)
            ReactGA.pageview('Actividades ' + currentAuthor.name);
        }
    }, [idAuthor])

    return (
        <>
            {
                idAuthor ?
                    <div className="screen-teachers">
                        {
                            currentAuthor &&
                            <div className="container-fluid">
                                <BackButton />
                                <div className="row">
                                    <div className="col d-flex align-items-center flex-column">
                                        <p className="text-center title m-0 mb-2">Actividades de mediación de lectura</p>
                                        <p className="subtitle">Esta serie de propuestas, para trabajar con grupos de lectura las obras leídas, busca dar una idea del tipo de dinámicas que pueden invitar a implicarse con los textos literarios y fomentar la creatividad a partir de ellos, redondeando la experiencia de lectura.</p>
                                    </div>
                                </div>
                                <div className="row teachers-box mt-5">
                                    <div className="col d-flex justify-content-center">
                                        <div className="questions-box mt-5 pb-4">
                                            <ol>
                                                {
                                                    currentAuthor.questions && currentAuthor.questions.map((currQuestion, index) => {
                                                        return (
                                                            <li key={'question' + index} className="question">
                                                                <p className="answer" dangerouslySetInnerHTML={{ __html: currQuestion} }></p>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <Redirect to={appRoutes.home} />
            }
        </>
    )
}
