import React from 'react'
import { useHistory } from "react-router-dom";

import './backButton.scss'

export const BackButton = () => {
    const history = useHistory();


    const goBack = () => {
        history.goBack()
    }

    return (
        <button className="btn btn-back" onClick={goBack}>
            <span>

            </span>
            Regresar
        </button>
    )
}
