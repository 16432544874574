
import ReactGA from 'react-ga';
import { AppRouter } from './router/AppRouter';
import './App.scss';
ReactGA.initialize('UA-198988701-1');

function App() {
  return (
    <div>
      <AppRouter />
    </div>
  );
}

export default App;
