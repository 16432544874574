import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { appRoutes } from '../utils/constants';

export const PublicRoute = ({ isAuthenticated,
    component: Component,
    ...rest }) => {
    return (
        <Route {...rest}
            component={(props) => (
                (isAuthenticated)
                    ? (<Redirect to={appRoutes.home} />)
                    : (<Component {...props} />)
            )}

        />
    )
}
