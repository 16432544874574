import React from 'react';

import { sponsor } from '../../utils/constants'

import './sponsors.scss'

export const Sponsors = () => {


    return (
        <div className='screen-sponsor'>
            <div className='sponsor-title sponsor-banner' >
                <div className='title-line'></div>
                <h2>
                    Auspiciantes
                </h2>
                <div className='sponsors-lineas'></div>
            </div>
            <div className='container sponsor-box'>

                {sponsor.map((spons, index) => {
                    return (
                        <div key={"sponsor" + index} className='sponsor' >
                            <img src={`./../../images/${spons.imgSponsor}`} alt={spons.name} />
                        </div>
                    )
                })}
            </div>
        </div>


    )
}
