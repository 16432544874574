export const appRoutes = {
    home: '/',
    author: '/author/:idAuthor',
    interview: '/interview/:idAuthor',
    book: '/book/:idAuthor',
    teachers: '/teachers/:idAuthor',
    criticalStudy: '/critical-study/:idAuthor',
    sponsors: '/sponsors',
    genre: '/genre/:idGenre'
}
export const shortRoutes = {
    home: '/',
    author: '/author/',
    interview: '/interview/',
    book: '/book/',
    teachers: '/teachers/',
    criticalStudy: '/critical-study/'
}

export const genres = [
    {
        idGenre: 1,
        name: 'Poesía',
    },
    {
        idGenre: 2,
        name: 'Narrativa'
    },
    {
        idGenre: 3,
        name: 'Ensayo'
    }

]

export const sponsor = [
    {
        idSponsor: 1,
        name: 'UDLA',
        imgSponsor: 'logo-udla.png'
    },
]